import React, { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { DialogTitle } from "components/DialogTitle";
import { UploadResourcesPreview } from "./UploadResourcesPreview";
import { UploadResourcesResult } from "./UploadResourcesResults";

export function UploadResourceDialog({ open, setOpen, lang }) {
  const [result, setResult] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploaded = res => {
    setResult(res);
  };

  return (
    <Dialog open={open}>
      <DialogTitle onClose={handleClose}>Subida masiva de recursos</DialogTitle>
      <DialogContent style={{ minWidth: 350 }} className="pt-2 pb-4">
        {result !== null && (
          <UploadResourcesResult
            handleBack={() => setResult(null)}
            handleClose={handleClose}
            result={result}
          />
        )}
        {result === null && (
          <UploadResourcesPreview onUploaded={handleUploaded} lang={lang} />
        )}
      </DialogContent>
    </Dialog>
  );
}
