import React from "react";
import * as _ from "lodash";

export const EmbeddedLang = ({ onSelect, title, initial }) => (
  <div className="embedded-lang">
    {title && <h6 className="MuiTypography-h6">{title}</h6>}

    <select
      name="cars"
      defaultValue={initial}
      onChange={e => {
        e.stopPropagation();
        onSelect(e.currentTarget.value);
      }}
    >
      <option value="ES">Español</option>
      <option value="CA">Catalan</option>
    </select>
  </div>
);
