import React from "react";
import ReactDOM from "react-dom";
import { history } from "helpers/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// styles
import "assets/css/material-dashboard-react.css?v=1.8.0";
import "assets/css/custom.css";
import "assets/css/lengths.css";

// core components
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import store from "./store.js";
import { Provider } from "react-redux";
import { PrivateRoute } from "helpers/PrivateRoute";
import "antd/dist/antd.css";
import { MainComponent } from "./Main";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";

import "moment/locale/es";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";
import { SnackbarProvider } from "notistack";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#2b74c4"
      }
    },
    overrides: {
      MuiButton: {
        contained: {
          borderRadius: 15
        },
        label: {
          textTransform: "none"
        },
        containedSecondary: {
          backgroundColor: "#fff",
          color: "#2b74c4",
          border: "2px solid #2b74c4",
          paddingTop: 4,
          paddingBottom: 4,
          "&:hover": {
            backgroundColor: "#eee"
          }
        }
      }
    }
  },
  esES
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateMomentUtils} locale="es">
        <SnackbarProvider
          variant="success"
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          preventDuplicate
        />
        <MainComponent>
          <Router history={history}>
            <Switch>
              <Route path="/auth" component={Auth} />
              <PrivateRoute path="/admin" component={Admin} />
              <Redirect from="/" to="/auth/login" />
            </Switch>
          </Router>
        </MainComponent>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
