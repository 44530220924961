import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { createTheme } from "@material-ui/core/styles";
import * as _ from "lodash";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "14px 5px 14px 5px"
      }
    }
  }
});

const columns = [
  { title: "id", field: "id", editable: "never" },
  { title: "Nombre", field: "nombre" }
];

export default function ModifyProfiles() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const dispatch = useDispatch();
  const profiles = useSelector(state => state.userReducer.profiles);

  useEffect(() => {
    getInitalRequest();
  }, []);

  const getInitalRequest = async () => {
    try {
      setLoading(true);

      await new Promise((resolve, reject) =>
        dispatch(
          userActions.getProfiles({
            resolve,
            reject,
            lang: "ES"
          })
        )
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const handleUpdate = async values => {
    if (values.nombre.length < 2)
      return setErrorMessage("En perfil debe mas de 2 letras.");

    setErrorMessage(null);

    try {
      await new Promise((resolve, reject) =>
        dispatch(userActions.updateProfil({ resolve, reject, ...values }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        {errorMessage && (
          <label style={{ color: "#e85353" }}>{errorMessage}</label>
        )}
        <MaterialTable
          title="Editar Perfiles"
          columns={columns}
          data={profiles}
          isLoading={loading}
          options={{ search: false }}
          editable={{
            onRowAdd: async newData => {
              const dataValues = _.values(newData);

              if (_.without(dataValues, "").length < 1) {
                setErrorMessage("Todos los campos deben estar completados");
              } else {
                setErrorMessage(null);
                dispatch(userActions.addProfile(newData));
              }
            },
            onRowUpdate: handleUpdate
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
