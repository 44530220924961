import actionTypes from "constants/actionTypes";

export const municipiosActions = {
  getMunicipios,
  addMunicipio,
  updateMunicipio
};

function getMunicipios(payload) {
  return {
    type: actionTypes.GET_MUNICIPIOS,
    payload: payload
  };
}

function addMunicipio(payload) {
  return {
    type: actionTypes.ADD_MUNICIPIO,
    payload: payload
  };
}

function updateMunicipio(payload) {
  return {
    type: actionTypes.UPDATE_MUNICIPIO,
    payload: payload
  };
}
