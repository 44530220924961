import React, { useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { useStatus } from "helpers/custom-hooks.helper";

const initialState = {
  ownership: "",
  ccaa: "",
  province: "",
  municipality: "",
  group: [],
  nivelsocioeconomico: []
};

const getMuiSelectedClass = (arr = []) =>
  arr.length > 0 ? "" : "Mui-selected";

export function CenterDashboardFilter(props) {
  const { onFilter } = props;
  const [state, setState] = useStatus(initialState);

  const dashboardFilterAssets = useSelector(
    state => state.centrosReducer.dashboardFilterAssets
  );

  const [listProvinces, setListProvinces] = useState([]);
  const [listMunicipalities, setListMunicipalities] = useState([]);

  const handleChange = event => {
    setState({
      [event.target.name]: event.target.value
    });
  };

  const AACCHandleChange = event => {
    let ccaa = "";
    let provincesList = [];

    if (event.target.value !== "0") {
      provincesList = dashboardFilterAssets.provinces.filter(
        p => p.comunidad_id == event.target.value
      );
      ccaa = event.target.value;
    }

    setListProvinces(provincesList);
    setListMunicipalities([]);

    setState({
      ccaa,
      province: "",
      municipality: ""
    });
  };

  const ProvinceHandleChange = event => {
    if (event.target.value) {
      let filterMunicipalities = dashboardFilterAssets.municipalities.filter(
        m => m.id_provincia == event.target.value
      );
      setListMunicipalities(filterMunicipalities);
    }

    setState({
      [event.target.name]: event.target.value,
      municipality: ""
    });
  };

  const handleSubmit = () => {
    onFilter(state);
  };

  const handleReset = () => {
    const defaultValues = {
      ownership: "",
      ccaa: "",
      province: "",
      municipality: "",
      group: [],
      nivelsocioeconomico: []
    };
    setListProvinces([]);
    setListMunicipalities([]);

    setState(defaultValues);

    onFilter();
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Titularidad</InputLabel>
          <Select
            value={state.ownership}
            onChange={handleChange}
            name="ownership"
            label="Titularidad"
          >
            <MenuItem value="">Todas</MenuItem>
            {dashboardFilterAssets.ownerships.map(({ label, ownership }) => (
              <MenuItem key={ownership} value={ownership}>
                {label ?? "Sin Titularidad"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} xl={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Comunidad Autónoma</InputLabel>
          <Select
            value={state.ccaa}
            onChange={AACCHandleChange}
            name="ccaa"
            label="Comunidad Autónoma"
          >
            <MenuItem value="">Todas</MenuItem>
            {dashboardFilterAssets.ccaa.map(({ id, nombre }) => (
              <MenuItem key={id} value={id}>
                {nombre ?? "Sin Comunidad"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={listProvinces.length === 0}
        >
          <InputLabel>Provincia</InputLabel>
          <Select
            value={state.province}
            onChange={ProvinceHandleChange}
            name="province"
            label="Provincia"
          >
            <MenuItem value="">Todas</MenuItem>
            {listProvinces.map(({ id, provincia }) => (
              <MenuItem key={id} value={id}>
                {provincia ?? "Sin Provincia"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={listMunicipalities.length === 0}
        >
          <InputLabel>Municipios</InputLabel>
          <Select
            value={state.municipality}
            onChange={handleChange}
            name="municipality"
            label="Municipios"
          >
            <MenuItem value="">Todos</MenuItem>
            {listMunicipalities.map(({ id, nombre }) => (
              <MenuItem key={id} value={id}>
                {nombre ?? "Sin Municipio"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Grupo</InputLabel>
          <Select
            value={state.group}
            onChange={handleChange}
            multiple
            name="group"
            label="Grupo"
            className="multi_group_selector"
            renderValue={selected =>
              selected.map(item => (
                <Chip key={item} className="mr-1" label={item ?? "Sin grupo"} />
              ))
            }
          >
            <MenuItem value="" className={getMuiSelectedClass(state.group)}>
              Todos
            </MenuItem>
            {dashboardFilterAssets.groups.map(item => (
              <MenuItem key={item} value={item}>
                {item ?? "Sin grupo"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={3} xl={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Nivel socio-económico</InputLabel>
          <Select
            value={state.nivelsocioeconomico}
            onChange={handleChange}
            multiple
            name="nivelsocioeconomico"
            label="Nivel socio-económico"
            className="multi_group_selector"
            renderValue={selected =>
              selected.map(id => (
                <Chip
                  key={id}
                  className="mr-1"
                  label={
                    dashboardFilterAssets.levels.find(el => el.id === id)?.label
                  }
                />
              ))
            }
          >
            <MenuItem
              value=""
              className={getMuiSelectedClass(state.nivelsocioeconomico)}
            >
              Todos
            </MenuItem>
            {dashboardFilterAssets.levels.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label ?? "Sin nivel socio-económico"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Button color="primary" onClick={handleReset} fullWidth>
              Limpiar
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

CenterDashboardFilter.propTypes = {
  onFilter: PropTypes.func.isRequired
};
