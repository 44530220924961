import actionTypes from "constants/actionTypes";

export const contenidosActions = {
  getContenidos,
  setShowInactives,
  updateContenido,
  deleteContenido,
  createContenido,
  documentos,
  delDocumento
};

function documentos() {
  return {
    type: actionTypes.GET_DOCUMENTOS,
    payload: ""
  };
}

function delDocumento(payload) {
  console.log(payload);

  return {
    type: actionTypes.DELETE_DOCUMENTOS,
    payload: payload
  };
}

function getContenidos(payload = {}) {
  return {
    type: actionTypes.GET_CONTENIDOS,
    payload
  };
}

function setShowInactives(payload) {
  return {
    type: "SET_SHOW_INACTIVES_CONTENIDOS",
    payload: payload
  };
}

function updateContenido(payload) {
  return {
    type: actionTypes.UPDATE_CONTENIDO,
    payload: payload
  };
}

function deleteContenido(payload) {
  return {
    type: actionTypes.DELETE_CONTENIDO_SAGA,
    payload: payload
  };
}

function createContenido(payload) {
  return {
    type: actionTypes.CREATE_CONTENIDO,
    payload: payload
  };
}
