import React, { useEffect, useState } from "react";
import { UploadResourceDialog } from "./components";
import MaterialTable from "material-table";
import * as _ from "lodash";
import Paper from "@material-ui/core/Paper";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { recursosActions } from "actions/recursosActions";
import { EmbeddedLang } from "views/components/Language";
import { cursosActions } from "actions/cursosActions";
import { clavesActions } from "actions/clavesActions";
import Grid from "@material-ui/core/Grid";
import { ContentForm } from "./ContentForm";

var theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "14px 5px 14px 5px"
      }
    },
    MuiInput: {
      root: {
        fontSize: "13px"
      }
    }
  }
});

const columns = [
  { title: "ID", field: "id", editable: "never" },
  { title: "Tipo", field: "tipom" },
  { title: "Titulo", field: "titulo" }
];

export default function ModifyContenido() {
  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("ES");

  const recursos = useSelector(state => state.recursosReducer.recursos);

  const [rowSelected, setRowSelected] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getInitalRequest();
  }, [lang]);

  useEffect(() => {
    dispatch(cursosActions.getCursos());
    dispatch(clavesActions.getClaves());

    dispatch(recursosActions.getPerspectivas());
    dispatch(recursosActions.getTipoMaterial());
  }, []);

  useEffect(() => {
    const uri = new URL(window.location.href);
    const id = uri.searchParams.get('e');
    
    if(recursos && id) {
      const resource = recursos.find(item => item.id === Number(id));

      setRowSelected(resource);
    } 
  }, [recursos]);

  const handleChangeRowSelected = data => {
    setRowSelected(data);
  };

  const getInitalRequest = async () => {
    try {
      setLoading(true);

      await new Promise((resolve, reject) =>
        dispatch(
          recursosActions.getRecursos({
            resolve,
            reject,
            lang
          })
        )
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {rowSelected === undefined && (
          <MuiThemeProvider theme={theme}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeRowSelected(null)}
                >
                  Añadir nuevo
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setOpenDialog(true)}
                >
                  Subida Masiva
                </Button>
              </Grid>
            </Grid>
            <MaterialTable
              title={
                <EmbeddedLang
                  title="Seleccione un registro"
                  initial={lang}
                  onSelect={setLang}
                />
              }
              columns={columns}
              isLoading={loading}
              data={recursos}
              options={{
                emptyRowsWhenPaging: false,
                addRowPosition: "first",
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                grouping: false
              }}
              onRowClick={(_, rowData) => {
                delete rowData.tableData;
                handleChangeRowSelected(rowData);
              }}
            />
          </MuiThemeProvider>
        )}
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3}>
          {rowSelected !== undefined && (
            <ContentForm
              lang={lang}
              setRowSelected={setRowSelected}
              rowSelected={rowSelected}
            />
          )}
        </Paper>
      </Grid>

      <UploadResourceDialog
        open={openDialog}
        setOpen={setOpenDialog}
        lang={lang}
      />
    </Grid>
  );
}
