import { useState, useRef, useEffect } from "react";

export const useStatus = initialState => {
  const [state, setState] = useState(initialState);

  const isSame = (prev, updateValue) => {
    let sameState = true;

    for (const key in updateValue) {
      if (updateValue[key] !== prev[key]) {
        sameState = false;
        break;
      }
    }

    return sameState;
  };

  const updateState = updateValue => {
    if (typeof updateValue === "function") {
      setState(prev => {
        let resp;
        const newState = updateValue(prev);

        if (isSame(prev, newState)) resp = prev;
        else resp = newState;

        return resp;
      });
    } else {
      setState(prev =>
        !isSame(prev, updateValue) ? { ...prev, ...updateValue } : prev
      );
    }
  };

  return [state, updateState];
};

export const useDidUpdate = (fn, watchers = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) fn();
    else didMount.current = true;
  }, watchers);
};

export const useForceUpdate = () => {
  const [value, setValue] = useState(false);

  const refreshComponent = () => {
    setValue(!value);
  };

  return refreshComponent;
};
