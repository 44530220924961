import { all } from "redux-saga/effects";
import watcherDoubleCounter from "./doubleCounter_saga";
import authentication_saga from "./authentication_saga";
import user_saga from "./user_saga.js";
import provincias_saga from "./provincias_saga.js";
import municipios_saga from "./municipios_saga.js";
import watcherComunidades from "./comunidades_saga.js";
import watcherCentros from "./centros_saga.js";
import watcherCargos from "./cargos_saga.js";
import watcherCursos from "./cursos_saga.js";
import watcherClaves from "./claves_saga.js";
import watcherContenidos from "./contenidos_saga.js";
import watcherFeedback from "./feedback_saga.js";
import watcherRecursos from "./recursos_saga.js";
import watcherToolkits from "./toolkits_saga.js";
import { settingsSaga } from "./settings.saga";
import { reportsSaga } from "./reports.saga";

/**
 * saga to yield all others
 */
export default function* root_saga() {
  yield all([
    watcherDoubleCounter(),
    watcherFeedback(),
    authentication_saga(),
    user_saga(),
    provincias_saga(),
    municipios_saga(),
    watcherComunidades(),
    watcherCentros(),
    watcherCargos(),
    watcherCursos(),
    watcherClaves(),
    watcherContenidos(),
    watcherRecursos(),
    watcherToolkits(),
    settingsSaga(),
    reportsSaga()
  ]);
}
