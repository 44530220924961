import React from "react";

import ModifyContenido from "./modify_contenido";

export default function ContenidosManagement() {
  return (
    <div>
      <ModifyContenido />
    </div>
  );
}
