import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import * as _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { cursosActions } from "actions/cursosActions";
import { EmbeddedLang } from "views/components/Language";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "14px 5px 14px 5px"
      }
    },
    MuiInput: {
      root: {
        fontSize: "13px"
      }
    }
  }
});

const columns = [
  { title: "ID", field: "id", editable: "never" },
  { title: "Nombre", field: "curso" },
  {
    title: "Activo",
    field: "activo",
    editable: "onUpdate",
    render: rowData => (
      <Checkbox
        color="primary"
        checked={
          rowData != undefined ? (rowData.activo === 1 ? true : false) : true
        }
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    ),
    editComponent: props => (
      <Checkbox
        color="primary"
        onChange={e =>
          e.target.checked == true ? props.onChange(1) : props.onChange(0)
        }
        checked={props.value == 1 ? true : false}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    )
  }
];

export default function ModifyCursos() {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const cursos = useSelector(state => state.cursosReducer.cursos);
  const [lang, setLang] = useState("ES");

  const dispatch = useDispatch();

  useEffect(() => {
    getInitalRequest();
  }, [lang]);

  const getInitalRequest = async () => {
    try {
      setLoading(true);

      await new Promise((resolve, reject) =>
        dispatch(
          cursosActions.getCursos({
            resolve,
            reject,
            lang
          })
        )
      );
    } catch (err) {
      console.log(err);
      setErrorMessage("Problemas con el servidor.");
    }

    setLoading(false);
  };

  const exitError = values => !values.curso || values.curso.length < 2;

  const handleSubmit = async values => {
    if (exitError(values))
      return setErrorMessage("Todos los campos deben ser rellenados");

    setLoading(true);
    setErrorMessage(null);

    try {
      await new Promise((resolve, reject) => {
        if (values.id)
          dispatch(
            cursosActions.updateCurso({ resolve, reject, lang, ...values })
          );
        else
          dispatch(
            cursosActions.createCurso({ resolve, reject, lang, ...values })
          );
      });
    } catch (err) {
      console.log(err);
      setErrorMessage("Problemas con el servidor.");
    }

    setLoading(false);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        {errorMessage && (
          <label style={{ color: "#e85353" }}>{errorMessage}</label>
        )}
        <MaterialTable
          title={
            <EmbeddedLang title="Añadir/Editar Cursos" onSelect={setLang} />
          }
          columns={columns}
          isLoading={loading}
          data={cursos}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
            addRowPosition: "first",
            search: false
          }}
          editable={{
            onRowAdd: handleSubmit,
            onRowUpdate: handleSubmit
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
