import React, { Fragment, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

const columns = [
  { title: 'Autor', field: 'name' },
  { title: 'Link', field: 'link' }
];

const useStyles = makeStyles({
  dialog: {
    padding: '0 !important' 
  }
});

export default function AuthorsTable(props) {
  const [datas, setDatas] = useState(props.datas ?? []);
  const [dialog, setDialog] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setDialog(false);
  }

  const handleSave = () => {
    props.onSave(datas.map(({tableData, ...others}) => others));

    setDialog(false);
  }

  return (
    <Fragment>
      <Button
        onClick={setDialog.bind(null, true)}
        color="primary"
        endIcon={<LaunchIcon />}
        variant='outlined'
      >
        Autores
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialog}
      >
        <DialogContent className={classes.dialog}>
          <MaterialTable
            title="Autores"
            columns={columns}
            data={datas}
            options={{
              search: false,
              pageSize: 6,
              pageSizeOptions: [] 
            }}
            editable={{
              onRowAdd: async (next) => {
                setDatas([...datas, next]);
              },
              onRowDelete: async ({name, link}) => {
                setDatas(
                  datas.filter((item) => (item.name !== name && item.link !== link) )
                );
              },
              onRowUpdate: async (next, prev) => {
                setDatas(
                  datas.map((item) => (item.name === prev.name && item.link === prev.link) ? next : item )
                );
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handleSave} color='primary'>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
