import actionTypes from "constants/actionTypes";

const initialState = { comunidades: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMUNIDADES_LIST":
      return {
        ...state,
        comunidades: action.payload.data
      };
    default:
      return state;
  }
};
