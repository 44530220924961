import axios from "axios";

const BASE_URL = process.env.REACT_APP_API;

export const xcService = {
  get,
  post,
  put,
  patch,
  postToken,
  deleteDetail,
  downloadExcel
};

async function get(apiEndpoint, lang = "ES") {
  try {
    const url = BASE_URL + apiEndpoint;
    const options = getOptions(lang);

    const response = await axios.get(url, options);

    return response;
  } catch (err) {
    console.log("Error on GET request");
    return Promise.reject(err);
  }
}

async function postToken(apiEndpoint, payload, lang = "ES") {
  try {
    const url = BASE_URL + apiEndpoint;
    const options = getOptions(lang);

    const response = await axios.post(url, payload, options);

    return response.data;
  } catch (err) {
    console.log("Error on GET request");
    return Promise.reject(err);
  }
}

async function post(apiEndpoint, payload) {
  try {
    const url = BASE_URL + apiEndpoint;
    const options = getOptions();

    const response = await axios.post(url, payload, options);

    return response.data;
  } catch (err) {
    console.log({ err });
    return Promise.reject(err);
  }
}

function put(apiEndpoint, payload, lang = "ES") {
  return axios
    .put(BASE_URL + apiEndpoint, payload, getOptions(lang))
    .then(response => {
      return response;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

async function patch(apiEndpoint, payload, lang = "ES") {
  return axios
    .patch(BASE_URL + apiEndpoint, payload, getOptions(lang))
    .then(response => {
      return response;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

async function deleteDetail(apiEndpoint) {
  return axios
    .delete(BASE_URL + apiEndpoint, getOptions())
    .then(response => {
      return response;
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

const getOptions = lang => ({
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": lang,
    Authorization: `bearer ${localStorage.getItem("token")}`
  }
});

export async function downloadExcel (
  url,
  lang = "ES",
  type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
) {
  console.log(url);
  const xhr = new XMLHttpRequest();
  xhr.responseType = "arraybuffer";
  xhr.open("GET", BASE_URL + url);
  xhr.setRequestHeader(
    "Authorization",
    `bearer ${localStorage.getItem("token")}`
  );
  xhr.setRequestHeader("Accept-Language", lang);

  await new Promise((resolve, reject) => {
    xhr.onload = function() {
      if (this.status === 200) {
        const blob = new Blob([xhr.response], { type });

        const objectUrl = URL.createObjectURL(blob);

        window.open(objectUrl);
        resolve();
      }
    };

    xhr.onerror = error => reject(error);

    xhr.send();
  });
};

export const postDownloadFile = async (
  url,
  body = {},
  type = "application/pdf"
) => {
  const xhr = new XMLHttpRequest();

  xhr.responseType = "arraybuffer";
  xhr.open("POST", BASE_URL + url);
  xhr.setRequestHeader(
    "Authorization",
    `bearer ${localStorage.getItem("token")}`
  );
  xhr.setRequestHeader("Content-type", "application/json");

  await new Promise((resolve, reject) => {
    xhr.onload = function() {
      if (this.status === 200) {
        const blob = new Blob([xhr.response], { type });

        const objectUrl = URL.createObjectURL(blob);

        window.open(objectUrl);
        resolve();
      }
    };

    xhr.onerror = error => reject(error);

    xhr.send(JSON.stringify(body));
  });
};
