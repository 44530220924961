const initialState = {
  recursos: [],
  resoursesFile: [],
  comunidadesRecurso: [],
  cursosRecurso: [],
  perspectives: [],
  materialTypes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_PERSPECTIVAS_DATA":
      return {
        ...state,
        perspectives: action.payload
      };

    case "SET_TIPOMATERIAL_DATA":
      return {
        ...state,
        materialTypes: action.payload
      };

    case "SET_RECURSOS_LIST":
      return {
        ...state,
        recursos: action.payload.data
      };

    case "SET_COMUNIDADES_RECURSO":
      return {
        ...state,
        comunidadesRecurso: action.payload.data
      };

    case "SET_CURSOS_RECURSO":
      return {
        ...state,
        cursosRecurso: action.payload.data
      };

    case "SET_FILE_RESOURCES":
      return {
        ...state,
        resoursesFile: action.payload
      };

    default:
      return state;
  }
};
