import React from "react";
import { FormControlLabel, makeStyles, Radio } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

export function SingleChoiseAnswer({ answers }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {answers.map(({ respuesta, id, centerAnswer }, i) => (
        <FormControlLabel
          key={i}
          value={id}
          control={<Radio disabled color="primary" checked={centerAnswer} />}
          label={respuesta}
        />
      ))}
    </div>
  );
}
