import React, { useEffect } from "react";

import MaterialTable from "material-table";
import * as _ from "lodash";

export default function ShowCursos_centro(props) {
  const [state, setState] = React.useState("");

  useEffect(() => {
    if (props.cursos && props.cursosCentros) {
      var cursos = props.cursos;
      var cursosCentros = props.cursosCentros;
      var cursosFinal = [];

      cursosCentros.map(item => {
        cursos.map(item2 => {
          if (item2.id == item.curso) {
            cursosFinal.push(item2);
          }
        });
      });

      setState({
        columns: [{ title: "Cursos", field: "curso" }],
        data: cursosFinal
      });
    }
  }, [props.cursos, props.cursosCentros]);

  return (
    <MaterialTable
      columns={state.columns}
      data={state.data}
      options={{
        toolbar: false,
        pageSize: 5,
        pageSizeOptions: [5, 10],
        addRowPosition: "first",
        search: false,
        rowStyle: {
          backgroundColor: "#EEE"
        }
      }}
    />
  );
}
