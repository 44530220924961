import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  DialogTitle as MuiDialogTitle,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    right: 2,
    top: 4
  }
});

export const DialogTitle = props => {
  const { children, onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="subtitle2">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
