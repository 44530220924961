import actionTypes from "constants/actionTypes";

export const userActions = {
  login,
  logout,
  getUsers,
  getUsersCentros,
  updateUser,
  getProfiles,
  deleteUser,
  updateProfil,
  addProfile,
  generateMunicipioList,
  altaMasiva,
  resetPassword,
  newUser,
  getLanguages
};

function newUser(payload) {
  return {
    type: actionTypes.NEW_USER,
    payload: payload
  };
}

function resetPassword(id) {
  return {
    type: actionTypes.RESET_PASSWORD,
    payload: id
  };
}

function altaMasiva(nombre, apellidos, email, tipo, codigocentro, grupo) {
  var payload = { nombre, apellidos, email, tipo, codigocentro, grupo };
  return {
    type: actionTypes.ALTA_MASIVA,
    payload: payload
  };
}

function login(email, password) {
  var payload = { email, password };
  return {
    type: actionTypes.LOGIN_USER,
    payload: payload
  };
}

function logout() {
  return {
    type: actionTypes.LOGOUT,
    payload: ""
  };
}

function updateUser(payload) {
  return {
    type: actionTypes.UPDATE_USER,
    payload: payload
  };
}

function deleteUser(payload) {
  return {
    type: actionTypes.DELETE_USER,
    payload: payload
  };
}

function getUsers() {
  return {
    type: actionTypes.GET_USERS,
    payload: ""
  };
}

function getLanguages() {
  return {
    type: actionTypes.GET_LANGUAGES,
    payload: ""
  };
}

function getUsersCentros(payload) {
  return {
    type: actionTypes.GET_USERS_CENTRO,
    payload: payload
  };
}

function getProfiles(payload) {
  return {
    type: actionTypes.GET_PROFILES,
    payload: payload
  };
}

function updateProfil(payload) {
  return {
    type: actionTypes.UPDATE_PROFIL,
    payload: payload
  };
}
function addProfile(payload) {
  return {
    type: actionTypes.ADD_PROFILE,
    payload: payload
  };
}

function generateMunicipioList(e, municipiosList) {
  var payload = { event: e, municipios: municipiosList };
  return {
    type: "GENERATE_MUN_LIST",
    payload: payload
  };
}
