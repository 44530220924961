import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export function UploadResourcesResult({ handleBack, handleClose, result }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={2}>
          {result?.states?.created > 0 && (
            <Grid item xs={12}>
              <Alert severity="success">
                Se han agregado {result.states.created} nuevos recursos.
              </Alert>
            </Grid>
          )}
          {result?.states?.updated > 0 && (
            <Grid item xs={12}>
              <Alert severity="success">
                Se han actualizado {result.states.updated} recursos.
              </Alert>
            </Grid>
          )}
          {result?.errors.map((item, i) => (
            <Grid item xs={12} key={i}>
              <Alert severity="error">
                {item.row && item.field
                  ? `${item.message} En la fila ${item.row} y campo ${item.field}.`
                  : item.message}
              </Alert>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={handleBack}
              component="label"
              color="primary"
              fullWidth
            >
              Subir mas archivos
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={handleClose}
              component="label"
              color="primary"
              fullWidth
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
