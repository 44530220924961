import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

const fields = {
  name: 'Nombre',
  email: 'Correo electrónico',
  mobile: 'Teléfono de contacto',
  position: 'Cargo',
  ownership: 'Titularidad del centro',
  ccaa: 'Comunidad Autónoma',
  province: 'Provincia',
  municipality: 'Municipio'
}

const useStyles = makeStyles({
  dialog: {
    padding: '0 !important' 
  }
});

const ownerships = {
  'PUBLIC': 'Público',
  'PRIVATE': 'Privado',
  'CONCERTED': 'Concertado',
};

export default function ContactInfo(props) {
  const [dialog, setDialog] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setDialog(false);
  }

  return (
    <Fragment>
      <Button
        onClick={setDialog.bind(null, true)}
        color="primary"
        endIcon={<LaunchIcon />}
        variant='outlined'
      >
        Datos de contacto
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialog}
      >
        <DialogTitle>Datos de contacto</DialogTitle>
        <DialogContent className={classes.dialog}>
          <List dense>
            {Object.keys(fields).map(item => (
              <ListItem key={item}>
                <ListItemText
                  primary={(item === 'ownership' && props.datas[item])
                    ? ownerships[props.datas[item]]
                    : props.datas[item]
                  }
                  secondary={fields[item]}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
