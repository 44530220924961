import { takeEvery, call, put } from "redux-saga/effects";
import { xcService } from "../services";

import { FETCH_ANSWERS_REPORT } from "constants/reports.constant";
import { saveAnswersReport } from "actions/reports.action";

export function* reportsSaga() {
  yield takeEvery(FETCH_ANSWERS_REPORT, fetchAnswersReport);
}

function* fetchAnswersReport({ payload: { resolve, reject, period } }) {
  try {
    let apiEndpoint = `/v15/reports/results?period=${period}`;

    const resp = yield call(xcService.get, apiEndpoint);

    if (resp) yield put(saveAnswersReport(resp.data));

    if (resolve) resolve();
  } catch (error) {
    console.log(error);

    if (reject) reject();
  }
}
