import React from "react";
import {
  Checkbox,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

export const MatrixAnswer = ({
  answers = [],
  headers = [],
  checkbox = true
}) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          {headers.map((item, i) => (
            <TableCell align="center" key={i}>
              <Typography variant="body2" style={{ color: "#9e9e9e" }}>
                {item}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {answers.map((item, i) => (
          <TableRow
            key={i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {item.map((item, j) =>
              j === 0 ? (
                <TableCell key={j}>
                  <Typography variant="body2" style={{ color: "#9e9e9e" }}>
                    {item}
                  </Typography>
                </TableCell>
              ) : (
                <TableCell align="center" key={j}>
                  {checkbox ? (
                    <Checkbox disabled checked={item} color="primary" />
                  ) : (
                    <Radio disabled checked={item} color="primary" />
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
