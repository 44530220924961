import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services/";
import { history } from "helpers/history";

function* loginUser({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v1/auth/login";
    const res = yield xcService.post(apiEndpoint, payload);

    if (res.token && res.perfil === 0) {
      console.log("token: " + res.token);
      localStorage.setItem("token", res.token);
      localStorage.setItem("refreshToken", res.token);
      localStorage.setItem("user", JSON.stringify(res));

      history.push("/admin/dashboard");
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }

  yield put({ type: "END_REQUEST" });
}

function* logoutUser() {
  localStorage.clear();
  history.push("/auth/login");
}

export default function* watcherAuthentication() {
  yield takeEvery(actionTypes.LOGIN_USER, loginUser);
  yield takeEvery(actionTypes.LOGOUT, logoutUser);
}
