import { CircularProgress, makeStyles } from "@material-ui/core";
import { bool } from "prop-types";
import React from "react";

const useStyles = makeStyles({
  wrapper: {
    zIndex: 101,
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    minHeight: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.75)"
  }
});

export function LoadingOverlay(props = {}) {
  const { customWrapperClass, isLoading } = props;
  const classes = useStyles();

  if (!isLoading) return null;

  return (
    <div className={`${classes.wrapper} ${customWrapperClass}`}>
      <CircularProgress size={36} />
    </div>
  );
}

LoadingOverlay.propTypes = {
  isLoading: bool.isRequired
};
