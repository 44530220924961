import { LocalStorageService } from "services";
import moment from "moment";

export function buildQueryParams(filter, setup, model) {
  const params = [];
  let response = "";

  if (model) LocalStorageService.save(`${model}_setup`, setup);

  if (setup) {
    setup = JSON.stringify(setup);
    params.push(`setup=${setup}`);
  }

  if (filter) {
    if (filter.endSent && filter.startSent) {
      const endSent = moment(filter.endSent)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      const startSent = moment(filter.startSent)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      filter = { ...filter, endSent, startSent };
    }

    if (model) LocalStorageService.save(`${model}_filter`, filter);

    filter = JSON.stringify(filter);
    params.push(`filter=${filter}`);
  }

  if (params.length > 0) {
    response += "?" + encodeURI(params.join("&"));
  }

  return response;
}
