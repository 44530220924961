export const FETCH_CENTER_QUIZ_FILTER = "FETCH_CENTER_QUIZ_FILTER";
export const SAVE_CENTER_QUIZ_FILTER = "SAVE_CENTER_QUIZ_FILTER";
export const SAVE_CENTER_DASHBOARD_FILTER = "SAVE_CENTER_DASHBOARD_FILTER";

export const FETCH_CENTER_QUIZ_INFO = "FETCH_CENTER_QUIZ_INFO";
export const SAVE_CENTER_QUIZ_INFO = "SAVE_CENTER_QUIZ_INFO";

export const SAVE_QUIZ_FILTER = "SAVE_QUIZ_FILTER";

export const FETCH_CENTER_KEY_ANSWERS = "FETCH_CENTER_KEY_ANSWERS";
export const SAVE_CENTER_KEY_ANSWERS = "SAVE_CENTER_KEY_ANSWERS";

export const DOWNLOAD_CENTER_INFO = "DOWNLOAD_CENTER_INFO";

export const FETCH_CENTER_QUIZ_FIELDS = "FETCH_CENTER_QUIZ_FIELDS";
export const SAVE_CENTER_QUIZ_FIELDS = "SAVE_CENTER_QUIZ_FIELDS";

export const PUT_CENTER_QUIZ_FIELDS = "PUT_CENTER_QUIZ_FIELDS";

export const FETCH_CENTERS_DASHBOARD = "FETCH_CENTERS_DASHBOARD";
export const SAVE_CENTERS_DASHBOARD = "SAVE_CENTERS_DASHBOARD";

export const DOWNLOAD_CENTER_DASHBOARD = "DOWNLOAD_CENTER_DASHBOARD";

export const FETCH_CENTER_DASHBOARD_FILTER = "FETCH_CENTER_DASHBOARD_FILTER";

export const SAVE_CENTER_FILTER = "SAVE_CENTER_FILTER";

export const SAVE_QUIZ_BY_PERIOD_FILTER = "SAVE_QUIZ_BY_PERIOD_FILTER";
export const FETCH_EVALUATION_DATA = "FETCH_EVALUATION_DATA";
export const SAVE_EVALUATION_DATA = "SAVE_EVALUATION_DATA";

export const DOWNLOAD_SIMPLE_PDF_REPORT = "DOWNLOAD_SIMPLE_PDF_REPORT";
