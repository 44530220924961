export default {
  INCREMENT_COUNTER: "INCREMENT_COUNTER",
  DECREMENT_COUNTER: "DECREMENT_COUNTER",
  INCREMENT_COUNTER_DOUBLE: "INCREMENT_COUNTER_DOUBLE",
  DECREMENT_COUNTER_DOUBLE: "DECREMENT_COUNTER_DOUBLE",
  SET_COUNTER: "SET_COUNTER",
  LOGIN_USER: "LOGIN_USER",
  GET_CENTROS: "GET_CENTROS",
  LOGOUT: "LOGOUT",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  GET_USERS: "GET_USERS",
  GET_USERS_CENTRO: "GET_USERS_CENTRO",
  GET_PROFILES: "GET_PROFILES",
  GET_LANGUAGES: "GET_LANGUAGES",
  DELETE_USER: "DELETE_USER",
  UPDATE_PROFIL: "UPDATE_PROFIL",
  ADD_PROFILE: "ADD_PROFILE",
  GET_PROVINCIAS: "GET_PROVINCIAS",
  ADD_PROVINCIA: "ADD_PROVINCIA",
  UPDATE_PROVINCIA: "UPDATE_PROVINCIA",
  GET_MUNICIPIOS: "GET_MUNICIPIOS",
  ADD_MUNICIPIO: "ADD_MUNICIPIO",
  UPDATE_MUNICIPIO: "UPDATE_MUNICIPIO",
  GET_COMUNIDADES: "GET_COMUNIDADES",
  UPDATE_CENTRO: "UPDATE_CENTRO",
  CREATE_CENTRO: "CREATE_CENTRO",
  GET_CARGOS: "GET_CARGOS",
  UPDATE_CARGO: "UPDATE_CARGO",
  CREATE_CARGO: "CREATE_CARGO",
  GET_CURSOS: "GET_CURSOS",
  GET_CURSOS_CENTROS: "GET_CURSOS_CENTROS",
  CREATE_CURSO: "CREATE_CURSO",
  UPDATE_CURSO: "UPDATE_CURSO",
  CREATE_CURSO_CENTRO: "CREATE_CURSO_CENTRO",
  DELETE_CURSO_CENTRO: "DELETE_CURSO_CENTRO",
  GET_CLAVES: "GET_CLAVES",
  GET_PREGUNTAS: "GET_PREGUNTAS",
  GET_COLORES: "GET_COLORES",
  SET_SHOW_INACTIVES_CLAVE: "SET_SHOW_INACTIVES_CLAVE",
  SET_SHOW_INACTIVES_TIPOS: "SET_SHOW_INACTIVES_TIPOS",
  SET_SHOW_INACTIVES_PREGUNTAS: "SET_SHOW_INACTIVES_PREGUNTAS",
  CREATE_CLAVE: "CREATE_CLAVE",
  CREATE_TIPO: "CREATE_TIPO",
  UPDATE_CLAVE: "UPDATE_CLAVE",
  UPDATE_TIPO: "UPDATE_TIPO",
  GET_TIPOS: "GET_TIPOS",
  CREATE_PREGUNTA: "CREATE_PREGUNTA",
  UPDATE_PREGUNTA: "UPDATE_PREGUNTA",
  CHANGE_CLAVE_SELECTED: "CHANGE_CLAVE_SELECTED",
  GET_CONTENIDOS: "GET_CONTENIDOS",
  SET_SHOW_INACTIVES_CONTENIDOS: "SET_SHOW_INACTIVES_CONTENIDOS",
  CREATE_CONTENIDO: "CREATE_CONTENIDO",
  UPDATE_CONTENIDO: "UPDATE_CONTENIDO",
  GET_RESPUESTAS: "GET_RESPUESTAS",
  SET_RESPUESTAS_BY_PREGUNTA: "SET_RESPUESTAS_BY_PREGUNTA",
  DELETE_CONTENIDO_SAGA: "DELETE_CONTENIDO_SAGA",
  UPDATE_RESPUESTAS: "UPDATE_RESPUESTAS",
  CREATE_RESPUESTAS: "CREATE_RESPUESTAS",

  GET_FEEDBACK: "GET_FEEDBACK",
  UPDATE_FEEDBACK: "UPDATE_FEEDBACK",
  CREATE_FEEDBACK: "CREATE_FEEDBACK",
  GET_RECURSOS: "GET_RECURSOS",
  GET_COMUNIDADES_RECURSO: "GET_COMUNIDADES_RECURSO",

  GET_CURSOS_RECURSO: "GET_CURSOS_RECURSO",

  UPDATE_RECURSO: "UPDATE_RECURSO",
  CREATE_RECURSO: "CREATE_RECURSO",
  DELETE_RECURSO: "DELETE_RECURSO",
  GET_TOOLKITS: "GET_TOOLKITS",
  CREATE_TOOLKITS: "CREATE_TOOLKITS",
  DELETE_TOOLKITS: "DELETE_TOOLKITS",
  UPDATE_TOOLKITS: "UPDATE_TOOLKITS",

  ALTA_MASIVA: "ALTA_MASIVA",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_DATA: "RESET_PASSWORD_DATA",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  NEW_USER: "NEW_USER",
  NEW_USER_DATA: "NEW_USER_DATA",
  NEW_USER_ERROR: "NEW_USER_ERROR",

  GET_CENTROS_ACABADOS_ERROR: "GET_CENTROS_ACABADOS_ERROR",

  GET_CENTROS_EMPEZADOS: "GET_CENTROS_EMPEZADOS",
  GET_CENTROS_EMPEZADOS_ERROR: "GET_CENTROS_EMPEZADOS_ERROR",

  DASHBOARD: "DASHBOARD",
  DASHBOARD_DATA: "DASHBOARD_DATA",
  DASHBOARD_ERROR: "DASHBOARD_ERROR",

  GET_DOCUMENTOS: "GET_DOCUMENTOS",

  GET_DOCUMENTOS_DATA: "GET_DOCUMENTOS_DATA",

  GET_DOCUMENTOS_ERROR: "GET_DOCUMENTOS_ERROR",

  PERIODO: "PERIODO",
  PERIODO_DATA: "PERIODO_DATA",
  PERIODO_ERROR: "PERIODO_ERROR",

  GET_PERSPECTIVAS: "GET_PERSPECTIVAS",
  SET_TIPOMATERIAL: "SET_TIPOMATERIAL",

  SET_TIPOMATERIAL_DATA: "SET_TIPOMATERIAL_DATA",
  GET_PERSPECTIVAS_DATA: "GET_PERSPECTIVAS_DATA",
  START_REQUEST: "START_REQUEST",
  END_REQUEST: "END_REQUEST",

  DELETE_DOCUMENTOS: "DELETE_DOCUMENTOS",
  DELETE_DOCUMENTOS_DATA: "DELETE_DOCUMENTOS_DATA",
  DELETE_DOCUMENTOS_ERROR: "DELETE_DOCUMENTOS_ERROR"
};
