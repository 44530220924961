import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { downloadExcel, postDownloadFile, xcService } from "../services";
import {
  DOWNLOAD_CENTER_INFO,
  FETCH_CENTER_KEY_ANSWERS,
  FETCH_CENTER_QUIZ_FIELDS,
  FETCH_CENTER_QUIZ_FILTER,
  PUT_CENTER_QUIZ_FIELDS,
  FETCH_CENTERS_DASHBOARD,
  FETCH_CENTER_DASHBOARD_FILTER,
  DOWNLOAD_CENTER_DASHBOARD,
  FETCH_EVALUATION_DATA,
  DOWNLOAD_SIMPLE_PDF_REPORT
} from "../constants/center.constact";
import {
  saveCenterDashboardFilters,
  saveCenterQuizFilters,
  saveCenterQuizInfo,
  saveCentersDashboard,
  saveEvaluationData
} from "../actions/centrosActions";
import { buildQueryParams } from "helpers/uri-parser.helper";
import { centrosActions } from "actions/centrosActions";
import { notification } from "antd";

export default function* watcherCentros() {
  yield takeEvery(actionTypes.GET_CENTROS, getCentros);
  yield takeEvery(actionTypes.UPDATE_CENTRO, updateCentro);
  yield takeEvery(actionTypes.CREATE_CENTRO, createCentro);
  yield takeEvery(actionTypes.GET_CURSOS_CENTROS, getCursosCentro);

  yield takeEvery(DOWNLOAD_CENTER_INFO, downloadCenterInfo);
  yield takeEvery(FETCH_CENTER_KEY_ANSWERS, fetchCenterKeyAnswers);
  yield takeEvery(FETCH_CENTER_QUIZ_FILTER, fetchCenterQuizFilter);
  yield takeEvery(actionTypes.GET_CENTROS_EMPEZADOS, fetchCenterQuizInfo);

  yield takeEvery(FETCH_CENTER_QUIZ_FIELDS, fetchCenterQuizColumns);
  yield takeEvery(PUT_CENTER_QUIZ_FIELDS, putCenterQuizColumn);

  yield takeEvery(actionTypes.PERIODO, newPeriodo);

  yield takeEvery(actionTypes.DASHBOARD, dashboard);

  yield takeEvery(FETCH_CENTERS_DASHBOARD, fetchCentersDashboard);
  yield takeEvery(FETCH_CENTER_DASHBOARD_FILTER, fetchCenterDashboardFilter);
  yield takeEvery(DOWNLOAD_CENTER_DASHBOARD, downloadCenterDashboard);

  yield takeEvery(FETCH_EVALUATION_DATA, fetchEvaluationData);

  yield takeEvery(DOWNLOAD_SIMPLE_PDF_REPORT, dowloadSimplePdfReport);
}
export const getCentrosList = state => state.centrosReducer.centros;

function* newPeriodo({ payload }) {
  try {
    let apiEndpoint = "/v7/nuevoperiodo";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: actionTypes.PERIODO_DATA, payload: res });
    } else {
      yield put({ type: actionTypes.PERIODO_ERROR, payload: "ERROR" });
    }
  } catch (e) {
    yield put({ type: actionTypes.PERIODO_ERROR, payload: e });
  }
}

function* dashboard() {
  try {
    let apiEndpoint = "/v2/centros/dashboard";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "DASHBOARD_DATA", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* putCenterQuizColumn({ payload }) {
  try {
    let endpoint = "/v15/fields-visibility";

    yield call(xcService.put, endpoint, payload);
  } catch (error) {
    console.log(error);
  }
}

function* fetchCenterQuizColumns({ payload }) {
  const { resolve, reject, table } = payload;

  try {
    let endpoint = `/v15/fields-visibility?table=${table}`;
    const res = yield call(xcService.get, endpoint);

    yield call(resolve, res.data);
  } catch (error) {
    console.log(error);
    yield call(reject, error);
  }
}

function* downloadCenterInfo({ payload }) {
  let { resolve, reject, setup } = payload;
  let filter = yield select(state => state.centrosReducer.quizFilter);

  try {
    const uri = "/v15/centros/download-info";
    const path =
      uri + buildQueryParams(filter, { order: setup.order }, "center_datas");

    yield call(downloadExcel, path);

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchCenterQuizFilter({ payload }) {
  const { resolve, reject } = payload;

  try {
    let endpoint = "/v15/centers/quiz-filters";
    const res = yield call(xcService.get, endpoint);

    if (res) yield put(saveCenterQuizFilters(res.data));

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchCenterKeyAnswers({ payload }) {
  let { resolve, reject, filter } = payload;

  try {
    filter = JSON.stringify(filter);

    const uri = "/v15/centros/key-answers";
    const encoded = encodeURI(`${uri}?filter=${filter}`);

    const res = yield call(xcService.get, encoded);

    yield call(resolve, res.data);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchCenterQuizInfo({ payload }) {
  let { resolve, reject, setup } = payload;
  let filter = yield select(state => state.centrosReducer.quizFilter);

  try {
    const uri = "/v15/centros/quizes";
    const path = uri + buildQueryParams(filter, setup, "center_datas");

    const { data } = yield call(xcService.get, path);

    if (data) {
      const parsed = data.data.map(item => ({
        ...item,
        quiz: item?.periodo?.label,
        status: item?.periodo?.status,
        keys: item?.periodo
      }));

      yield put(saveCenterQuizInfo({ ...data, data: parsed }));
    }

    resolve();
  } catch (error) {
    reject(error);
  }
}

function* getCentros() {
  yield put({ type: "START_REQUEST" });
  try {
    let apiEndpoint = "/v2/centros?filter=actives";

    const res = yield call(xcService.get, apiEndpoint);

    if (res) {
      yield put({ type: "SET_CENTROS_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  } finally {
    yield put({ type: "END_REQUEST" });
  }
}

function* updateCentro({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v2/centros";
    const res = yield call(xcService.patch, apiEndpoint, payload);

    if (res) {
      yield put(centrosActions.getCentros(true));
    }
  } catch (e) {
    console.log(e);
    yield put({ type: "API_ERRORED", payload: e });
  } finally {
    yield put({ type: "END_REQUEST" });
  }
}

function* createCentro({ payload }) {
  try {
    let apiEndpoint = "/v2/centros";

    const res = yield call(xcService.put,apiEndpoint, payload);

    if (res) {
      notification.open({
        message: '¡Centro creado exitosamente!',
        type: 'success',
        placement: 'bottomLeft',
      });

      yield put(centrosActions.getCentros(true));
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getCursosCentro({ payload }) {
  try {
    let apiEndpoint = "/v2/cursoscentros/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_CURSOS_CENTROS", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
function* fetchCentersDashboard({ payload }) {
  let { resolve, reject, filter } = payload;

  try {
    const uri = "/v2/centers/dashboard";
    const path = uri + buildQueryParams(filter);

    const { data } = yield call(xcService.get, path);

    if (data) {
      yield put(saveCentersDashboard({ ...data }));
    }

    resolve();
  } catch (error) {
    reject(error);
  }
}
function* downloadCenterDashboard({ payload }) {
  let { resolve, reject, filter } = payload;

  try {
    const uri = "/v2/centers/download-centers";
    const path = uri + buildQueryParams(filter);

    yield call(downloadExcel, path);

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* dowloadSimplePdfReport({ payload }) {
  let { resolve, reject, ...other } = payload;

  try {
    const uri = "/v3/keys/results-report/download";

    yield call(postDownloadFile, uri, other);

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchCenterDashboardFilter({ payload }) {
  const { resolve, reject } = payload;

  try {
    let endpoint = "/v2/centers/dashboard-filters";
    const res = yield call(xcService.get, endpoint);
    if (res) yield put(saveCenterDashboardFilters(res.data));

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* fetchEvaluationData({ payload }) {
  let { resolve, reject, filter } = payload;

  try {
    const uri = "/v2/centros/evaluation-period";
    const path = uri + buildQueryParams(filter);

    const res = yield call(xcService.get, path);
    if (res) yield put(saveEvaluationData(res.data.centers));

    resolve();
  } catch (error) {
    reject(error);
  }
}
