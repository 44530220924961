import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { saveQuizFilter } from "../../../actions/centrosActions";
import { KeyboardDatePicker } from "@material-ui/pickers";

export function QuizInfoFilter(props) {
  const { onFilter, ...others } = props;
  const { filterAsset, quizFilter } = useSelector(
    state => state.centrosReducer
  );
  const dispatch = useDispatch();

  const [listMunicipalities, setListMunicipalities] = useState([]);

  useEffect(() => {
    if(quizFilter.ccaa && filterAsset.municipalities) {
      const selected = filterAsset.municipalities.filter(
        m => quizFilter.ccaa.includes(m.comunidad_id)
      );

      if(selected) setListMunicipalities(selected);
    }
  }, [filterAsset.municipalities]);

  const setState = state => 
    dispatch(saveQuizFilter(state));

  const aaccHandleChange = (event) => {
    let ccaa = [];
    let municipalities = [];
    let value = event.target.value;

    if (value.length !== 0) {
      municipalities = filterAsset.municipalities.filter(m => value.includes(m.comunidad_id));
      ccaa = value;
    }

    setListMunicipalities(municipalities);

    setState({
      ...quizFilter,
      ccaa,
      municipality: '',
    });
  }

  const handleChangeDate = (value, name) => {
    setState({
      ...quizFilter,
      [name]: value
    });
  };

  const handleChange = event => {
    setState({
      ...quizFilter,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    onFilter();
  };

  const handleReset = () => {
    const defaultValues = {
      name: "",
      code: "",
      group: [],
      ccaa: [],
      municipality: '',
      ownership: "",
      period: "",
      startSent: null,
      endSent: null,
      status: ""
    };
    dispatch(saveQuizFilter(defaultValues));

    onFilter();
  };

  return (
    <Card {...others}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField
              fullWidth
              value={quizFilter.name}
              name="name"
              onChange={handleChange}
              variant="outlined"
              label="Nombre"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField
              fullWidth
              value={quizFilter.code}
              name="code"
              onChange={handleChange}
              variant="outlined"
              label="Código"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Titularidad</InputLabel>
              <Select
                value={quizFilter.ownership}
                onChange={handleChange}
                name="ownership"
                label="Titularidad"
              >
                {filterAsset.ownerships.map(({ ownership, label }) => (
                  <MenuItem key={ownership} value={ownership}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Evaluación</InputLabel>
              <Select
                value={quizFilter.period}
                onChange={handleChange}
                name="period"
                label="Evaluación"
              >
                {filterAsset.periods.map(({ id, periodo }) => (
                  <MenuItem key={id} value={periodo}>
                    {periodo}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Grupo</InputLabel>
              <Select
                value={quizFilter.group}
                onChange={handleChange}
                multiple
                name="group"
                label="Grupo"
                className="multi_group_selector"
                renderValue={selected =>
                  selected.map(item => (
                    <Chip
                      key={item}
                      className="mr-1"
                      label={item ?? "Sin grupo"}
                    />
                  ))
                }
              >
                {filterAsset.groups.map(item => (
                  <MenuItem key={item} value={item}>
                    {item ?? "Sin grupo"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Estado</InputLabel>
              <Select
                value={quizFilter.status}
                onChange={handleChange}
                name="status"
                label="Estado"
              >
                {filterAsset.quizStates.map(({ code, label }) => (
                  <MenuItem key={code} value={code}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Comunidad Autónoma</InputLabel>
              <Select
                value={quizFilter.ccaa}
                onChange={aaccHandleChange}
                multiple
                name="ccaa"
                label="Comunidad Autónoma"
                className="multi_group_selector"
                renderValue={selected =>
                  selected.map((id, i) => (
                    <Chip
                      key={i}
                      className="mr-1"
                      label={filterAsset.ccaa.find(el => el.id === id)?.nombre}
                    />
                  ))
                }
              >
                {filterAsset.ccaa.map(({ id, nombre }, i) => (
                  <MenuItem key={i} value={id}>
                    {nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              disabled={listMunicipalities.length === 0}
            >
              <InputLabel>Municipio</InputLabel>
              <Select
                value={quizFilter.municipality}
                onChange={handleChange}
                name="municipality"
                label="Municipio"
              >
                <MenuItem value="">Todos</MenuItem>
                {listMunicipalities.map(({ id, nombre }) =>
                  <MenuItem key={id} value={id}>{nombre}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <KeyboardDatePicker
              size="small"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/yyyy"
              label="Finalización(desde)"
              value={quizFilter.startSent}
              onChange={value => handleChangeDate(value, "startSent")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <KeyboardDatePicker
              size="small"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/yyyy"
              label="Finalización(hasta)"
              value={quizFilter.endSent}
              onChange={value => handleChangeDate(value, "endSent")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button color="primary" onClick={handleReset} fullWidth>
                  Deshacer
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Aplicar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
