import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { downloadExcel } from "services";
import spainMap from "assets/img/spain_map.png";
import { LoadingOverlay } from "components/LoadingOverlay";
import { MapPoint } from "./components/MapPoint";
import { fetchAnswersReport } from "actions/reports.action";
import html2canvas from "html2canvas";

export const ownershipMap = {
  'PUBLIC': "Público",
  'CONCERTED': "Concertado",
  'PRIVATE': "Privado",
  'WITHOUT_OWNERSHIP': "Sin titularidad",
};

export const DownloadResults = () => {
  const [choosePeriod, setChoosePeriod] = useState(0);
  const [loading, setLoading] = useState(false);
  const mapChar = useRef(null);
  const dispatch = useDispatch();

  const {
    settingsReducer: { periods },
    reportsReducer: { communitiesReport, ownershipReport }
  } = useSelector(store => store);

  useEffect(() => {
    getAnswersReport();
  }, []);

  const [totalCenters] = useMemo(() => {
    let totalCenters = 0;

    for (let item of communitiesReport) {
      totalCenters += item.total;
    }

    return [totalCenters];
  }, [communitiesReport]);

  const getAnswersReport = async (period = 0) => {
    try {
      setLoading(true);
      await new Promise((resolve, reject) =>
        dispatch(fetchAnswersReport({ resolve, reject, period }))
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const handleSubmit = event => {
    const period = event.target.value;

    getAnswersReport(period);
    setChoosePeriod(period);
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      await downloadExcel(`/v15/reports/excel-results?period=${choosePeriod}`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadImage = async () => {
    try {
      const canvas = await html2canvas(mapChar.current);
      const image = canvas.toDataURL();

      const anchor = document.createElement("a");
      anchor.href = image;
      anchor.download = "mapa-de-resultados";

      document.body.appendChild(anchor);
      anchor.click();

      anchor.remove();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        spacing={5}
        style={{ position: "relative" }}
        justifyContent="center"
        direction="row-reverse"
        container
      >
        <Grid item style={{ width: 360 }}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <FormHelperText>Periodo:</FormHelperText>
              <Select
                style={{ height: 46 }}
                value={choosePeriod}
                onChange={handleSubmit}
                fullWidth
                variant="outlined"
                displayEmpty
              >
                <MenuItem value={0}>
                  <Typography variant="body2">Todos los periodos</Typography>
                </MenuItem>
                {periods.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    <Typography variant="body2">{item.periodo}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleDownload}
                style={{ textTransform: "none" }}
                fullWidth
                startIcon={<GetApp />}
              >
                Descargar Excel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                style={{ textTransform: "none" }}
                onClick={handleDownloadImage}
                startIcon={<GetApp />}
              >
                Descargar Imagen
              </Button>
            </Grid>
          </Grid>

          <Typography
            style={{ borderBottom: "2px solid #dfe1e5" }}
            className="mb-2 mt-4"
            variant="subtitle2"
          >
            Totales
          </Typography>
          <div className="py-2 px-3">
            <Grid container justifyContent="space-between">
              <Typography variant="body2">Centro totales</Typography>
              <Typography variant="body1">{totalCenters}</Typography>
            </Grid>
          </div>

          <Typography
            style={{ borderBottom: "2px solid #dfe1e5" }}
            className="mb-2 mt-4"
            variant="subtitle2"
          >
            Por titularidad
          </Typography>
          <div className="py-2 px-3">
            {Object.keys(ownershipMap).map(item => (
              <Grid key={item} container justifyContent="space-between">
                <Typography variant="body2">{ownershipMap[item]}</Typography>
                <Typography variant="body1">
                  {ownershipReport[item] ?? 0}
                </Typography>
              </Grid>
            ))}
          </div>
        </Grid>
        <Grid item>
          <div ref={mapChar} style={{ position: "relative" }}>
            <img width={500} src={spainMap} alt="Map de espania" />
            {communitiesReport.map((item, i) =>
              item.total > 0 ? (
                <MapPoint
                  key={i}
                  cid={item.id}
                  quantity={item.total}
                  localLabel={item.name}
                  totalSpain={totalCenters}
                />
              ) : null
            )}
          </div>
        </Grid>
        <LoadingOverlay isLoading={loading} />
      </Grid>
    </>
  );
};
