const initialState = { cargos: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CARGOS_LIST":
      return {
        ...state,
        cargos: action.payload.data
      };
    default:
      return state;
  }
};
