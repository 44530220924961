import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  container: {
    display: "inline-flex",
    alignItems: "baseline",
    columnGap: 5
  },
  sample: ({ color }) => ({
    width: 18,
    height: 18,
    border: "1px solid #0003",
    borderRadius: 2,
    background:
      typeof color === "string" ? color : `linear-gradient(${color.join(",")})`
  })
});

export const Legend = props => {
  const { className = "", label = 50, color = "#b4b8d0" } = props;

  const clasess = useStyles({ color });

  return (
    <div className={`${clasess.container} ${className}`}>
      <span className={clasess.sample}></span>
      <Typography variant="body2">{label}</Typography>
    </div>
  );
};
