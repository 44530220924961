import actionTypes from "constants/actionTypes";

export const cursosActions = {
  getCursos,
  getCursosCentros,
  updateCurso,
  setShowInactives,
  createCurso,
  changeCentroSelected,
  deleteCursoCentro,
  createCursoCentro
};

function getCursos(payload = {}) {
  return {
    type: actionTypes.GET_CURSOS,
    payload
  };
}

function createCurso(payload) {
  return {
    type: actionTypes.CREATE_CURSO,
    payload: payload
  };
}

function updateCurso(payload) {
  return {
    type: actionTypes.UPDATE_CURSO,
    payload: payload
  };
}

function getCursosCentros(payload) {
  return {
    type: actionTypes.GET_CURSOS_CENTROS,
    payload: payload
  };
}

function setShowInactives(payload) {
  return {
    type: "SET_SHOW_INACTIVES",
    payload: payload
  };
}
function changeCentroSelected(payload) {
  return {
    type: "SET_CENTRO_SELECTED",
    payload: payload
  };
}

function createCursoCentro(payload) {
  return {
    type: actionTypes.CREATE_CURSO_CENTRO,
    payload: payload
  };
}

function deleteCursoCentro(payload) {
  return {
    type: actionTypes.DELETE_CURSO_CENTRO,
    payload: payload
  };
}
