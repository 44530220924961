import actionTypes from "constants/actionTypes";

const initialState = { municipios: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_MUNICIPIOS_LIST":
      return {
        ...state,
        municipios: action.payload.data
      };

    default:
      return state;
  }
};
