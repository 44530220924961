import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModifyCentros from "./ModifyCentros.js";
import { centrosActions } from "actions/centrosActions";
import { municipiosActions } from "actions/municipiosActions";
import { provinciasActions } from "actions/provinciasActions";
import { comunidadesActions } from "actions/comunidadesActions";

export default function CentrosManagement() {
  const dispatch = useDispatch();
  var centros = useSelector(state => state.centrosReducer.centros);
  var municipios = useSelector(state => state.municipiosReducer.municipios);
  var provincias = useSelector(state => state.provinciasReducer.provincias);
  var comunidades = useSelector(state => state.provinciasReducer.comunidades);
  // var munList = useSelector(state => state.userReducer.munGenerated);
  var munSelected = useSelector(state => state.userReducer.munSelected);

  var apiDONE = useSelector(state => state.loading.APIactions);

  useEffect(() => {
    dispatch(centrosActions.getCentros(true));
    dispatch(municipiosActions.getMunicipios());
    dispatch(provinciasActions.getProvincias());
    dispatch(comunidadesActions.getComunidades());
  }, [apiDONE]);

  return (
    centros &&
    municipios &&
    provincias &&
    comunidades && (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <ModifyCentros
              centros={centros}
              municipios={municipios}
              provincias={provincias}
              comunidades={comunidades}
              // munList={munList}
              munSelected={munSelected}
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  );
}
