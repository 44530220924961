import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherCargos() {
  yield takeEvery(actionTypes.GET_CARGOS, getCargos);

  yield takeEvery(actionTypes.UPDATE_CARGO, updateCargo);
  yield takeEvery(actionTypes.CREATE_CARGO, addCargo);
}

export const getCargosList = state => state.cargosReducer.cargos;

function* getCargos() {
  try {
    let apiEndpoint = "/v4/cargos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_CARGOS_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* addCargo({ payload }) {
  let cargosList = yield select(getCargosList);
  payload.id = cargosList[cargosList.length - 1].id + 1;

  try {
    let apiEndpoint = "/v4/cargos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.put(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: "" });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* updateCargo({ payload }) {
  try {
    let apiEndpoint = "/v4/cargos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.patch(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
