import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import SelectInput from "@material-ui/core/Select/SelectInput";

import { history } from "helpers/history";

export default function* watcherMunicipios() {
  yield takeEvery(actionTypes.GET_MUNICIPIOS, getMunicipios);
  yield takeEvery(actionTypes.ADD_MUNICIPIO, addMunicipio);
  yield takeEvery(actionTypes.UPDATE_MUNICIPIO, updateMunicipio);
}

export const getMunicipiosList = state => state.municipiosReducer.municipios;

function* getMunicipios() {
  try {
    let apiEndpoint = "/v6/municipios";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_MUNICIPIOS_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* addMunicipio({ payload }) {
  yield put({ type: "START_REQUEST" });
  let municipiosList = yield select(getMunicipiosList);
  payload.id_municipio =
    municipiosList[municipiosList.length - 1].id_municipio + 1;
  console.log(payload);

  try {
    let apiEndpoint = "/v6/municipios";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).postToken(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: "" });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* updateMunicipio({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v6/municipios";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.patch(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: "" });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}
