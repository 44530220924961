import actionTypes from "constants/actionTypes";
import { LocalStorageService } from "../services";
import {
  SAVE_CENTER_QUIZ_FILTER,
  SAVE_CENTER_QUIZ_INFO,
  SAVE_QUIZ_FILTER,
  SAVE_CENTERS_DASHBOARD,
  SAVE_CENTER_DASHBOARD_FILTER,
  SAVE_EVALUATION_DATA
} from "../constants/center.constact";

const initialState = {
  centros: [],
  cursosCentros: null,
  quizCenterInfo: [],
  count: 0,
  dashboardFilterAssets: {
    periods: [],
    groups: [],
    ownerships: [],
    quizStates: [],
    ccaa: [],
    provinces: [],
    municipalities: [],
    levels: []
  },
  filterAsset: {
    periods: [],
    groups: [],
    ownerships: [],
    quizStates: [],
    ccaa: []
  },
  quizFilter: {
    name: "",
    code: "",
    group: [],
    municipality: "",
    ccaa: [],
    ownership: "",
    period: "",
    startSent: null,
    endSent: null,
    status: "",
    ...LocalStorageService.get("center_datas_filter")
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CENTROS_LIST":
      return {
        ...state,
        centros: action.payload.data
      };
    case SAVE_CENTER_DASHBOARD_FILTER:
      return {
        ...state,
        dashboardFilterAssets: action.payload
      };
    case SAVE_CENTER_QUIZ_FILTER:
      return {
        ...state,
        filterAsset: action.payload
      };
    case SAVE_QUIZ_FILTER:
      return {
        ...state,
        quizFilter: { ...initialState.quizFilter, ...action.payload }
      };
    case SAVE_CENTER_QUIZ_INFO:
      return {
        ...state,
        quizCenterInfo: action.payload.data,
        count: action.payload.count
      };
    case SAVE_CENTERS_DASHBOARD:
      return {
        ...state,
        count: action.payload.count,
        total: action.payload.total
      };
    case "DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: action.payload.data
      };

    case actionTypes.PERIODO_DATA:
      return {
        ...state,
        periodoData: action.payload.data,
        periodoDataError: null
      };
    case actionTypes.PERIODO_ERROR:
      return {
        ...state,
        periodoDataError: action.payload.data,
        periodoData: null
      };
    case SAVE_EVALUATION_DATA:
      return {
        ...state,
        evaluationsByPeriod: action.payload
      };
    default:
      return state;
  }
};
