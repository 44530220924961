import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import { recursosActions } from "actions/recursosActions";
import { useDispatch } from "react-redux";
import { downloadExcel } from "services";

const useStyle = makeStyles({
  notes: {
    borderLeft: "3px solid #a0aabf",
    listStyle: "none",
    padding: "5px 15px",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    rowGap: 12,
    fontStyle: "italic"
  }
});

export function UploadResourcesPreview({ onUploaded, lang }) {
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const classes = useStyle();

  const handleFileChange = async event => {
    const file = event.target.files[0];

    handleSendFile(file);
  };

  const getBase64FromUrl = async url => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSendFile = async file => {
    try {
      setUploading(true);

      const fileUrl = URL.createObjectURL(file);
      const xlsxBase64 = await getBase64FromUrl(fileUrl);
      const base64result = xlsxBase64 ? xlsxBase64.split(",")[1] : "";

      const result = await new Promise((resolve, reject) =>
        dispatch(
          recursosActions.setResources({
            resolve,
            reject,
            data: base64result,
            lang
          })
        )
      );

      await new Promise((resolve, reject) =>
        dispatch(
          recursosActions.getRecursos({
            resolve,
            reject,
            lang
          })
        )
      );

      onUploaded(result);
    } catch (err) {
      console.log(err);

      setUploading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);

      await downloadExcel("/v11/resources/download", lang);
    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ul className={classes.notes}>
            <li>
              <Typography variant="body2" color="textSecondary">
                Las etiquetas(título, descripción y cuerpo) del recurso de
                subiran/bajaran en el idioma "{lang}".
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="textSecondary">
                Si desea agregar nuevos recursos deje en blanco la columna "ID",
                en caso de que rellene la columna "ID" el recurso se editara.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="textSecondary">
                Por favor no cambie los nombres de las columnas, para ver los
                nombres exactos de las columnas podría descarga una plantillas.
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                variant="contained"
                onChange={handleFileChange}
                component="label"
                color="primary"
                disabled={uploading}
                startIcon={
                  uploading && <CircularProgress color="inherit" size={18} />
                }
              >
                Subir recursos
                <input
                  hidden
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                />
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleDownload}
                variant="outlined"
                disabled={downloading}
                startIcon={
                  downloading && <CircularProgress color="inherit" size={18} />
                }
              >
                Bajar recursos
              </Button>
            </Grid>
            <Grid item>
              <Button
                href={`${process.env.REACT_APP_API}/public/template/resources.xlsx`}
                color="primary"
                download
                target="_blank"
                variant="text"
              >
                Descargar plantilla
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
