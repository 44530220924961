import { SAVE_ANSWERS_REPORT } from "constants/reports.constant";

const initialState = {
  communitiesReport: [],
  ownershipReport: []
};

export const reportsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_ANSWERS_REPORT:
      state = {
        ...state,
        communitiesReport: action.payload.communities,
        ownershipReport: action.payload.ownership
      };
  }

  return state;
};
