import actionTypes from "constants/actionTypes";

export const feedbackActions = {
  getFeedbacks,
  updateFeedback,
  createFeedback
};

function getFeedbacks(payload = {}) {
  return {
    type: actionTypes.GET_FEEDBACK,
    payload
  };
}

function updateFeedback(payload) {
  return {
    type: actionTypes.UPDATE_FEEDBACK,
    payload: payload
  };
}

function createFeedback(payload) {
  return {
    type: actionTypes.CREATE_FEEDBACK,
    payload: payload
  };
}
