import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay } from "../../components";
import {
  fetchCentersDashboard,
  fetchCenterDashboardFilter,
  downloadCenterDashboard
} from "../../actions/centrosActions";
import { CenterDashboardFilter } from "views/CentersDashboard/components/CenterDashboardFilter";
import { useStatus } from "helpers/custom-hooks.helper";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";

const initialState = {
  loading: false,
  filter: null,
  downloading: false
};

export function CentersDashboard() {
  const [state, setState] = useStatus(initialState);
  const { count, total } = useSelector(state => state.centrosReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    initialRequest();
  }, []);

  const initialRequest = async () => {
    try {
      setState({ loading: true });
      await getCentersDashboard();

      await new Promise((resolve, reject) =>
        dispatch(fetchCenterDashboardFilter({ reject, resolve }))
      );
    } catch (error) {
      console.log(error);
    }

    setState({ loading: false });
  };

  const handleFilter = async filter => {
    try {
      setState({ loading: true, filter });
      await getCentersDashboard(filter);
    } catch (error) {
      console.log(error);
    }

    setState({ loading: false });
  };

  const getCentersDashboard = filter => {
    return new Promise((resolve, reject) =>
      dispatch(
        fetchCentersDashboard({
          reject,
          resolve,
          filter
        })
      )
    );
  };

  const handleDownload = async () => {
    try {
      setState({ downloading: true });
      await new Promise((resolve, reject) =>
        dispatch(
          downloadCenterDashboard({
            reject,
            resolve,
            filter: state.filter
          })
        )
      );
    } catch (error) {
      console.log(error);
    }

    setState({ downloading: false });
  };

  return (
    <div>
      <LoadingOverlay isLoading={state.loading} />
      <Grid className="mt-3" container justifyContent="space-between">
        <Box width={185}></Box>
        <Grid item>
          <Typography align="center" variant="h6">
            CENTROS XCELENCE
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            color="default"
            onClick={handleDownload}
            startIcon={
              state.downloading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                <DescriptionOutlined />
              )
            }
          >
            Exportar listado
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="baseline" justifyContent="center">
        <Grid item>
          <Typography variant="subtitle1">{total}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">&nbsp;centros</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="baseline"
        className="mb-3"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="subtitle1">{count}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">&nbsp;centros</Typography>
        </Grid>
      </Grid>
      <CenterDashboardFilter onFilter={handleFilter} />
    </div>
  );
}
