import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { cursosActions } from "actions/cursosActions";

export default function* watcherCursos() {
  yield takeEvery(actionTypes.GET_CURSOS, getCursos);
  yield takeEvery(actionTypes.CREATE_CURSO, createCurso);
  yield takeEvery(actionTypes.UPDATE_CURSO, updateCurso);
  yield takeEvery(actionTypes.DELETE_CURSO_CENTRO, deleteCursoCentro);
  yield takeEvery(actionTypes.CREATE_CURSO_CENTRO, createCursoCentro);
}
export const getCursosList = state => state.cursosReducer.cursos;

function* getCursos({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v2/cursos";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res) yield put({ type: "SET_CURSOS_LIST", payload: res });

    if (resolve) yield call(resolve, undefined);
  } catch (e) {
    if (resolve) yield call(reject, "Interal Error");
  }
}

function* createCurso({ payload }) {
  const { resolve, reject, lang, ...content } = payload;

  try {
    let apiEndpoint = "/v2/cursos";

    const res = yield call(xcService.postToken, apiEndpoint, content, lang);

    if (res) yield put(cursosActions.getCursos({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateCurso({ payload }) {
  const { resolve, reject, lang, ...course } = payload;
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v2/curso/" + payload.id;

    const res = yield call(xcService.put, apiEndpoint, course, lang);

    if (res) yield put(cursosActions.getCursos({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* createCursoCentro({ payload }) {
  yield put({ type: "START_REQUEST" });
  let cursosList = yield select(getCursosList);
  payload.id = cursosList[cursosList.length - 1].id + 1;
  try {
    let apiEndpoint =
      "/v2/cursoscentros/" + payload.idCentro + "/" + payload.idCurso;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.put(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* deleteCursoCentro({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint =
      "/v2/cursoscentros/" + payload.idCentro + "/" + payload.idCurso;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.deleteDetail(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
