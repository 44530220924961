import React from "react";
import { Route, Redirect } from "react-router-dom";

import Admin from "layouts/Admin.js";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={props => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user ? user.token : null;
      const perfil = user ? user.perfil : null;

      if (!token || perfil != 0) {
        return <Redirect to="/auth/login" />;
      } else {
        const path = props.location.pathname;

        if (path.match(/^\/admin\w*/)) return <Admin {...props} />;
      }

      return (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      );
    }}
  />
);
