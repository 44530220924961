import {
  Grid,
  LinearProgress,
  withStyles,
  Typography
} from "@material-ui/core";
import { number, string } from "prop-types";
import React, { useState } from "react";

const coordinates = {
  1: [273, 294], // Andalucia
  2: [103, 165], // Aragon
  3: [22, 335], // Asturias
  4: [235, 48], // Balticas
  5: [414, 432], // Canarias
  6: [30, 276], // Catabria
  7: [79, 288], // Castilla y Leos
  8: [193, 226], // Castilla - La Mancha
  9: [86, 92], // Catalunia
  10: [187, 163], // Valencia
  11: [197, 341], // Extremadura
  12: [41, 391], // Galicia
  13: [143, 266], // Madrid
  14: [253, 186], // Murcia
  15: [50, 196], // Navarra
  16: [36, 226], // Pais Vasco
  17: [68, 222] // La rioja
};

const BorderLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#dfe1e5"
  },
  bar: {
    backgroundColor: "#383b41"
  }
})(LinearProgress);

export const MapPoint = props => {
  const [showPopup, setShowPopup] = useState(false);

  const { cid = 1, totalSpain = 0, quantity, localLabel = "" } = props;

  const top = coordinates[cid]?.[0];
  const right = coordinates[cid]?.[1];
  const min = 20;

  let length = ((140 - min) * quantity) / totalSpain;
  length += min;

  return (
    <>
      <div
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
        style={{
          width: length,
          height: length,
          borderRadius: "50%",
          position: "absolute",
          border: "1px solid",
          backgroundColor: "#00aead",
          borderColor: "#3e6398",
          transform: "translate(50%, -50%)",
          zIndex: 0,
          fontSize: length * 0.4,
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          top,
          right
        }}
      >
        {quantity}
      </div>

      {showPopup && (
        <div
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            top: top + length / 2,
            right: right,
            borderRadius: 10,
            minWidth: 150,
            zIndex: 1,
            transform: "translate(50%, 5px)",
            padding: "12px 10px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 13px"
          }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="body2">
                {localLabel}: <b>{quantity}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Toda España: <b>{totalSpain}</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

MapPoint.propTypes = {
  localPercent: number,
  totalSpain: number,
  localLabel: string.isRequired,
  quantity: number.isRequired,
  cid: number
};
