import actionTypes from "constants/actionTypes";
import actionResources from "constants/resources_constant";

export const recursosActions = {
  getRecursos,
  getComunidadesRecurso,
  getCursosRecurso,
  updateRecurso,
  createRecurso,
  deleteRecurso,
  getPerspectivas,
  getTipoMaterial,
  setResources
};

function getPerspectivas() {
  return {
    type: actionTypes.GET_PERSPECTIVAS
  };
}

function getTipoMaterial() {
  return {
    type: actionTypes.SET_TIPOMATERIAL
  };
}
function getRecursos(payload = {}) {
  return {
    type: actionTypes.GET_RECURSOS,
    payload
  };
}

function getComunidadesRecurso(payload) {
  return {
    type: actionTypes.GET_COMUNIDADES_RECURSO,
    payload: payload
  };
}

function getCursosRecurso(payload) {
  return {
    type: actionTypes.GET_CURSOS_RECURSO,
    payload: payload
  };
}

function updateRecurso(payload) {
  return {
    type: actionTypes.UPDATE_RECURSO,
    payload: payload
  };
}

function createRecurso(payload) {
  return {
    type: actionTypes.CREATE_RECURSO,
    payload: payload
  };
}

function deleteRecurso(payload) {
  return {
    type: actionTypes.DELETE_RECURSO,
    payload: payload
  };
}

function setResources(payload) {
  return {
    type: actionResources.SET_FILE_RESOURCES,
    payload: payload
  };
}
