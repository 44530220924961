import {
  FETCH_ANSWERS_REPORT,
  SAVE_ANSWERS_REPORT
} from "constants/reports.constant";

export function fetchAnswersReport(payload = {}) {
  return {
    type: FETCH_ANSWERS_REPORT,
    payload
  };
}

export function saveAnswersReport(payload = {}) {
  return {
    type: SAVE_ANSWERS_REPORT,
    payload
  };
}
