import React from "react";

const pallette = {
  success: ["#E6F8F0", "#88D98E"],
  error: ["#FFF5F6", "#FE3F55"],
  info: ["#EBF1F8", "#0089F3"],
  warning: ["#FFF4D8", "#FEBE00"]
};

export const SwChip = ({ children, color, onCLick, ...props }) => {
  return (
    <div
      {...props}
      onClick={onCLick}
      style={{
        textAlign: "center",
        backgroundColor: pallette[color][0],
        fontSize: '0.8rem',
        padding: "5px 8px",
        borderRadius: 20,
        border: "1px solid " + pallette[color][1],
        color: "#000",
        cursor: onCLick ? "pointer" : "auto",
        width: "fit-content",
        whiteSpace: "nowrap"
      }}
    >
      {children}
    </div>
  );
};
