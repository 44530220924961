import React, { useEffect, useMemo, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOverlay } from "../../components";
import { Grid } from "@material-ui/core";
import { ShowHtml, EmailTrackerFilter } from "./components";
import { columnsBuilder } from "./email-tracker-columns";
import { LocalStorageService } from "../../services";
import { fetchSentEmails } from "actions/settings.action";

const initialSetup = {
  size: 5,
  page: 0,
  order: [],
  ...LocalStorageService.get("sent_email_setup")
};

export default function CenterTestInfo() {
  const [loading, setLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState();
  const [setup, setSetup] = useState(initialSetup);
  const filterRef = useRef(LocalStorageService.get("sent_email_filter"));
  const dispatch = useDispatch();

  const { sentEmails, totalSentEmails } = useSelector(
    state => state.settingsReducer
  );

  useEffect(() => {
    handleFilter();
  }, [setup]);

  const columns = useMemo(() => {
    const visibility = LocalStorageService.get("sent_email_columns", []);

    return columnsBuilder(setHtmlContent, visibility);
  }, []);

  const handleFilter = async filter => {
    setLoading(true);

    if (filter) {
      filterRef.current = filter;
      setup.page = 0;
    }

    try {
      await getCenterQuizInfo();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getCenterQuizInfo = () => {
    return new Promise((resolve, reject) =>
      dispatch(
        fetchSentEmails({
          reject,
          resolve,
          setup,
          filter: filterRef.current
        })
      )
    );
  };

  const handleColumnVisibility = (column, action) => {
    const target = columns.find(item => item.name === column);
    const visible = action === "add";

    if (target?.options) target.options.display = visible;

    const visibility = LocalStorageService.get("sent_email_columns", []);
    visibility[column] = visible;

    LocalStorageService.save("sent_email_columns", visibility);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <LoadingOverlay isLoading={loading} />
        <EmailTrackerFilter
          className="mb-4"
          onFilter={handleFilter}
          defaultFilter={filterRef.current}
        />
        <MUIDataTable
          title="Lista de correos"
          data={sentEmails}
          columns={columns}
          options={{
            serverSide: true,
            print: false,
            search: false,
            responsive: "vertical",
            download: false,
            filter: false,
            count: totalSentEmails,
            page: setup.page,
            rowsPerPage: setup.size,
            rowsPerPageOptions: [5, 10, 20],
            sortOrder: { direction: setup.order[1], name: setup.order[0] },
            expandableRows: false,
            selectableRows: "none",
            textLabels: {
              body: { noMatch: "😥 Sin centros que mostrar." },
              pagination: { rowsPerPage: "Filas por pagina" }
            },
            onChangeRowsPerPage: size => {
              setSetup({ ...setup, size });
            },
            onViewColumnsChange: handleColumnVisibility,
            onChangePage: currentPage => {
              setSetup({
                ...setup,
                page: currentPage
              });
            },
            onColumnSortChange: (column, direction) => {
              setSetup({
                ...setup,
                order: direction !== "none" ? [column, direction] : []
              });
            }
          }}
        />
      </Grid>
      <ShowHtml html={htmlContent} setHtml={setHtmlContent} />
    </Grid>
  );
}
