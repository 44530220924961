import actionTypes from "constants/actionTypes";
import {
  FETCH_RESULTS_KEYS,
  SAVE_RESULTS_KEYS_REPORT
} from "../constants/keys.constant";

export const clavesActions = {
  getClaves,
  getColores,
  getTipos,
  getPreguntas,
  createClave,
  createTipo,
  createPregunta,
  updateClave,
  updatePregunta,
  updateTipo,
  setShowInactives,
  setShowInactivesTipos,
  setShowInactivesPreguntas,
  getRespuestas,
  updateRespuestas,
  changeClaveSelected,
  createRespuestas
};

function getClaves(payload = {}) {
  return {
    type: actionTypes.GET_CLAVES,
    payload
  };
}

function getColores() {
  return {
    type: actionTypes.GET_COLORES,
    payload: ""
  };
}

function getPreguntas(payload = {}) {
  return {
    type: actionTypes.GET_PREGUNTAS,
    payload
  };
}
function getTipos() {
  return {
    type: actionTypes.GET_TIPOS,
    payload: ""
  };
}

function createClave(payload) {
  return {
    type: actionTypes.CREATE_CLAVE,
    payload: payload
  };
}

function createTipo(payload) {
  return {
    type: actionTypes.CREATE_TIPO,
    payload: payload
  };
}

function createPregunta(payload) {
  return {
    type: actionTypes.CREATE_PREGUNTA,
    payload: payload
  };
}

function updateClave(payload) {
  return {
    type: actionTypes.UPDATE_CLAVE,
    payload: payload
  };
}

function updatePregunta(payload) {
  return {
    type: actionTypes.UPDATE_PREGUNTA,
    payload: payload
  };
}

function updateTipo(payload) {
  return {
    type: actionTypes.UPDATE_TIPO,
    payload: payload
  };
}
function setShowInactives(payload) {
  return {
    type: actionTypes.SET_SHOW_INACTIVES_CLAVE,
    payload: payload
  };
}

function setShowInactivesTipos(payload) {
  return {
    type: actionTypes.SET_SHOW_INACTIVES_TIPOS,
    payload: payload
  };
}

function setShowInactivesPreguntas(payload) {
  return {
    type: actionTypes.SET_SHOW_INACTIVES_PREGUNTAS,
    payload: payload
  };
}

function changeClaveSelected(payload) {
  return {
    type: "CHANGE_CLAVE_SELECTED",
    payload: payload
  };
}

function getRespuestas(payload) {
  return {
    type: actionTypes.GET_RESPUESTAS,
    payload: payload
  };
}

function updateRespuestas(payload) {
  return {
    type: actionTypes.UPDATE_RESPUESTAS,
    payload: payload
  };
}

function createRespuestas(payload) {
  return {
    type: actionTypes.CREATE_RESPUESTAS,
    payload: payload
  };
}

//#region Results Keys report

export function fetchResultsKeys(payload = {}) {
  return {
    type: FETCH_RESULTS_KEYS,
    payload
  };
}

export function saveResultsKeys(payload = {}) {
  return {
    type: SAVE_RESULTS_KEYS_REPORT,
    payload
  };
}
//#endregion
