import actionTypes from "constants/actionTypes";
import {
  SAVE_CENTER_QUIZ_FILTER,
  FETCH_CENTER_QUIZ_FILTER,
  SAVE_CENTER_QUIZ_INFO,
  SAVE_QUIZ_FILTER,
  FETCH_CENTER_KEY_ANSWERS,
  SAVE_CENTER_KEY_ANSWERS,
  DOWNLOAD_CENTER_INFO,
  FETCH_CENTER_QUIZ_FIELDS,
  SAVE_CENTER_QUIZ_FIELDS,
  PUT_CENTER_QUIZ_FIELDS,
  FETCH_CENTERS_DASHBOARD,
  SAVE_CENTERS_DASHBOARD,
  DOWNLOAD_CENTER_DASHBOARD,
  FETCH_CENTER_DASHBOARD_FILTER,
  SAVE_CENTER_DASHBOARD_FILTER,
  SAVE_CENTER_FILTER,
  FETCH_EVALUATION_DATA,
  SAVE_EVALUATION_DATA,
  SAVE_QUIZ_BY_PERIOD_FILTER,
  DOWNLOAD_SIMPLE_PDF_REPORT
} from "../constants/center.constact";

export const centrosActions = {
  getCentros,
  updateCentro,
  createCentro,
  fetchCenterQuizFilter,
  fetchCenterQuizInfo,
  dashboard,
  newPeriodo
};

function newPeriodo(payload) {
  return {
    type: actionTypes.PERIODO,
    payload: payload
  };
}

function dashboard() {
  return {
    type: actionTypes.DASHBOARD,
    payload: ""
  };
}

export function fetchCenterQuizFilter(payload = {}) {
  return {
    type: FETCH_CENTER_QUIZ_FILTER,
    payload
  };
}

export function saveCenterQuizFilters(payload = {}) {
  return {
    type: SAVE_CENTER_QUIZ_FILTER,
    payload
  };
}

export function saveQuizFilter(payload = {}) {
  return {
    type: SAVE_QUIZ_FILTER,
    payload
  };
}

export function fetchCenterQuizInfo(payload = {}) {
  return {
    type: actionTypes.GET_CENTROS_EMPEZADOS,
    payload
  };
}

export function dowloadCenterInfo(payload = {}) {
  return {
    type: DOWNLOAD_CENTER_INFO,
    payload
  };
}

export function saveCenterQuizInfo(payload = {}) {
  return {
    type: SAVE_CENTER_QUIZ_INFO,
    payload
  };
}

export function fetchCenterKeyAnswers(payload = {}) {
  return {
    type: FETCH_CENTER_KEY_ANSWERS,
    payload
  };
}

export function putCenterQuizColumn(payload = {}) {
  return {
    type: PUT_CENTER_QUIZ_FIELDS,
    payload
  };
}

export function saveCenterQuizColumns(payload = {}) {
  return {
    type: SAVE_CENTER_QUIZ_FIELDS,
    payload
  };
}

export function fetchCenterQuizColumns(payload = {}) {
  return {
    type: FETCH_CENTER_QUIZ_FIELDS,
    payload
  };
}

export function saveCenterKeyAnswers(payload = {}) {
  return {
    type: SAVE_CENTER_KEY_ANSWERS,
    payload
  };
}

function getCentros(actives = false) {
  return {
    type: actionTypes.GET_CENTROS,
    payload: actives
  };
}

function updateCentro(payload) {
  return {
    type: actionTypes.UPDATE_CENTRO,
    payload: payload
  };
}

function createCentro(payload) {
  return {
    type: actionTypes.CREATE_CENTRO,
    payload: payload
  };
}

//#region CENTER DASHBOARD
export function fetchCentersDashboard(payload = {}) {
  return {
    type: FETCH_CENTERS_DASHBOARD,
    payload
  };
}

export function saveCentersDashboard(payload = {}) {
  return {
    type: SAVE_CENTERS_DASHBOARD,
    payload
  };
}

export function downloadCenterDashboard(payload = {}) {
  return {
    type: DOWNLOAD_CENTER_DASHBOARD,
    payload
  };
}

export function dowloadSimplePdfReport(payload = {}) {
  return {
    type: DOWNLOAD_SIMPLE_PDF_REPORT,
    payload
  };
}

export function fetchCenterDashboardFilter(payload = {}) {
  return {
    type: FETCH_CENTER_DASHBOARD_FILTER,
    payload
  };
}

export function saveCenterDashboardFilters(payload = {}) {
  return {
    type: SAVE_CENTER_DASHBOARD_FILTER,
    payload
  };
}

export function saveCenterFilter(payload = {}) {
  return {
    type: SAVE_CENTER_FILTER,
    payload
  };
}

export function saveQuizByPeriodFilter(payload = {}) {
  return {
    type: SAVE_QUIZ_BY_PERIOD_FILTER,
    payload
  };
}

export function fetchEvaluationData(payload = {}) {
  return {
    type: FETCH_EVALUATION_DATA,
    payload
  };
}

export function saveEvaluationData(payload = {}) {
  return {
    type: SAVE_EVALUATION_DATA,
    payload
  };
}
