const initialState = { contenidos: [], showInactives: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTENIDOS_LIST":
      return {
        ...state,
        contenidos: action.payload.data
      };

    case "SET_SHOW_INACTIVES_CONTENIDOS":
      return {
        ...state,
        showInactives: action.payload.data
      };
    case "GET_DOCUMENTOS_DATA":
      return {
        ...state,
        documentos: action.payload.data
      };

    default:
      return state;
  }
};
