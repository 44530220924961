const initialState = {
  cargos: [],
  centros: null,
  users: null,
  profiles: [],
  languages: [],
  munGenerated: null,
  munSelected: null,
  provinciaSelected: null,
  usersSeleccionados: null,
  altamasivo: null,
  errormasivo: null,
  resetData: null,
  resetError: null,
  newuserData: null,
  newUserError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DEL_NEW_USER":
      return {
        ...state,
        newuserData: null,
        newUserError: null
      };
    case "NEW_USER_DATA":
      return {
        ...state,
        newuserData: action.payload.data
      };
    case "NEW_USER_ERROR":
      return {
        ...state,
        newUserError: action.payload.data
      };
    case "DELE_RESET_PASSWORD_DATA":
      return {
        ...state,
        resetData: null
      };
    case "RESET_PASSWORD_DATA":
      return {
        ...state,
        resetData: action.payload.data
      };
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        resetError: action.payload.data
      };
    case "SET_CARGOS":
      return {
        ...state,
        cargos: action.payload.data
      };
    case "SET_LANGUAGES_LIST":
      return {
        ...state,
        languages: action.payload.data
      };
    case "SET_CENTROS":
      return {
        ...state,
        centros: action.payload.data
      };
    case "SET_USERS_LIST":
      return {
        ...state,
        users: action.payload.data
      };
    case "ALTA_USER_MASIVO":
      return {
        ...state,
        altamasivo: action.payload
      };
    case "ERROR_USER_MASIVO":
      return {
        ...state,
        errormasivo: action.payload.data
      };
    case "SET_USERS_CENTRO_LIST":
      return {
        ...state,
        usersSeleccionados: action.payload.data
      };
    case "SET_PROFILES_LIST":
      return {
        ...state,
        profiles: action.payload.data
      };
    case "GENERATE_MUN_LIST":
      var municipiosGenerated = [];
      var changeMuni = true;
      var provinciaSelectedActual =
        action.payload.event.rowData.provincias_id_provincia;
      action.payload.municipios.map(item => {
        if (
          item.id_provincia ===
          action.payload.event.rowData.provincias_id_provincia
        ) {
          municipiosGenerated.push(item);
        }
      });
      var municipioSelected = "";
      action.payload.municipios.map(item => {
        if (
          item.id_municipio ===
          action.payload.event.rowData.municipios_id_municipio
        ) {
          municipioSelected = item;
        }
      });

      if (
        provinciaSelectedActual === null ||
        JSON.stringify(provinciaSelectedActual) ===
          JSON.stringify(state.provinciaSelected)
      ) {
        changeMuni = false;
      }

      if (
        JSON.stringify(state.munGenerated) ===
        JSON.stringify(municipiosGenerated)
      ) {
        if (changeMuni) {
          return {
            ...state,
            provinciaSelected: provinciaSelectedActual,
            munSelected: municipioSelected
          };
        } else {
          return {
            ...state,
            munSelected: " "
          };
        }
      } else {
        if (changeMuni) {
          return {
            ...state,
            munGenerated: municipiosGenerated,
            provinciaSelected: provinciaSelectedActual,
            munSelected: municipioSelected
          };
        } else {
          return {
            ...state,
            munSelected: " "
          };
        }
      }

    default:
      return state;
  }
};
