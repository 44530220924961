import actionTypes from "constants/actionTypes";

export const cargosActions = {
  getCargos,
  addCargo,
  updateCargo
};

function getCargos(payload) {
  return {
    type: actionTypes.GET_CARGOS,
    payload
  };
}

function updateCargo(payload) {
  return {
    type: actionTypes.UPDATE_CARGO,
    payload: payload
  };
}

function addCargo(payload) {
  return {
    type: actionTypes.CREATE_CARGO,
    payload: payload
  };
}
