export class LocalStorageService {
  static save(name = "", value = {}) {
    const stringified = JSON.stringify(value);

    localStorage.setItem(name, stringified);
  }

  static get(name = "", defualtValue = {}) {
    let response;

    try {
      const value = localStorage.getItem(name) ?? defualtValue;

      response = JSON.parse(value);
    } catch {
      response = defualtValue;
    }

    return response;
  }
}
