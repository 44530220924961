import actionTypes from "constants/actionTypes";

export const comunidadesActions = {
  getComunidades
};

function getComunidades() {
  return {
    type: actionTypes.GET_COMUNIDADES,
    payload: ""
  };
}
