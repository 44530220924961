import React from "react";
import { SwChip } from "../../components";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";

export const columnsBuilder = (setTargetEmail, config = []) => {
  const baseColumn = [
    {
      name: "receiver",
      label: "Receptor",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "subject",
      label: "Asunto",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "sent_at",
      label: "Enviado",
      options: {
        sortThirdClickReset: true,
        customBodyRender: value => value ? moment(value).format("DD/MM/YYYY HH:mm") : "Sin fecha"
      }
    },
    {
      name: "status",
      label: "Estado",
      options: {
        sortThirdClickReset: true,
        customBodyRender: value =>
          value ? (
            <SwChip color="success" className="px-2">
              Enviado
            </SwChip>
          ) : (
            <SwChip color="error" className="px-2">
              No enviado
            </SwChip>
          )
      }
    },
    {
      name: "html",
      label: "Acciones",
      options: {
        sort: false,
        customBodyRender: value => (
          <Button
            variant="contained"
            color="secondary"
            onClick={setTargetEmail.bind(null, value)}
          >
            Ver cuerpo
          </Button>
        )
      }
    }
  ];

  baseColumn.forEach(item => {
    if (!item.options.display) {
      const visibility = config[item.name];

      item.options.display = Boolean(visibility ?? true);
    }
  });

  return baseColumn;
};
