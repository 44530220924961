import React, { useEffect } from "react";
import MaterialTable from "material-table";
import * as _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";
import { Button } from "@material-ui/core";

export default function ModifyUsers(props) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [state, setState] = React.useState("");
  const profiles = useSelector(state => state.userReducer.profiles);
  const cargos = useSelector(state => state.cargosReducer.cargos);

  const dispatch = useDispatch();

  const resetPassword = id => {
    dispatch(userActions.resetPassword(id));
  };

  useEffect(() => {
    if (props.users) {
      let cargosJSON = {};
      cargos.map(item => {
        cargosJSON[item.id] = item.nombre;
      });
      setState({
        columns: [
          {
            title: "id",
            field: "id",
            editable: "never",
            hidden: "true",
            cellStyle: {
              color: "grey",
              width: "50%"
            },
            headerStyle: {
              width: "50%",
              color: "grey"
            }
          },
          { title: "Email", field: "email" },
          { title: "Nombre", field: "nombre" },
          { title: "Apellidos", field: "apellidos" },
          { title: "DNI", field: "dni" },
          { title: "Teléfono", field: "telefono" },
          {
            title: "Perfil",
            field: "perfil",
            editComponent: props => (
              <Select
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
              >
                {profiles.map(item => {
                  return (
                    <MenuItem key={item.id} value={item.nombre}>
                      {item.nombre}
                    </MenuItem>
                  );
                })}
              </Select>
            )
          },
          { title: "Cargo", field: "cargos_id", lookup: cargosJSON },
          {
            title: "Activo",
            field: "activo",
            render: rowData => (
              <Checkbox
                disabled={true}
                color="secondary"
                checked={rowData.activo === 1 ? true : false}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            ),
            editComponent: props => (
              <Checkbox
                color="primary"
                onChange={e =>
                  e.target.checked === true
                    ? props.onChange(1)
                    : props.onChange(0)
                }
                checked={props.value === 1 ? true : false}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            )
          },
          {
            title: "Resetear",
            field: "id",
            editable: "never",
            render: rowData => (
              <Button color="primary" onClick={() => resetPassword(rowData.id)}>
                Resetear contraseña
              </Button>
            )
          }
        ],
        data: props.users
      });
    }
  }, [props.users, props.profiles, props.centros, props.cargos]);

  return (
    <div>
      {errorMessage && (
        <label style={{ color: "#e85353" }}>{errorMessage}</label>
      )}
      <MaterialTable
        title="Editar Usuarios"
        columns={state.columns}
        data={state.data}
        options={{
          toolbar: false,
          pageSize: 5,
          pageSizeOptions: [5, 10],
          addRowPosition: "first",
          search: false,
          rowStyle: {
            backgroundColor: "#EEE"
          }
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              newData.centros_id = props.centro;
              var dataValues = _.values(newData);

              if (_.without(dataValues, "").length < 11) {
                reject(
                  setErrorMessage("Todos los campos deben estar completados")
                );
              } else {
                if (typeof newData.centros_id != "number") {
                  newData.centros_id = newData.centros_id.id;
                }
                console.log(newData);
                setErrorMessage(null);
                dispatch(userActions.updateUser(newData));
                resolve();
              }
            }),

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(userActions.deleteUser(oldData));
              resolve();
            })
        }}
      />
    </div>
  );
}
