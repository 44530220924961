import actionTypes from "constants/actionTypes";

export const countActions = {
  increment,
  decrement,
  incrementDouble,
  decrementDouble
};

function increment() {
  return {
    type: actionTypes.INCREMENT_COUNTER,
    payload: 1
  };
}

function decrement() {
  return {
    type: actionTypes.DECREMENT_COUNTER,
    payload: 1
  };
}

function incrementDouble() {
  return {
    type: actionTypes.INCREMENT_COUNTER_DOUBLE,
    payload: ""
  };
}

function decrementDouble() {
  return {
    type: actionTypes.DECREMENT_COUNTER_DOUBLE,
    payload: ""
  };
}
