import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';

export default function FileField(props) {
  const handleChange = (event) => {
    const file = event.currentTarget.files?.[0];

    if(file) {
      const size = (file.size ?? 0) / 1024 / 1024;
      let message;

      if (size > 10) {
        message = 'Tamaño máximo permitido en 10Mb';
      }

      if(message) {
        enqueueSnackbar(message, { variant: 'info' });
      } else {
        props.setFieldValue(props.name, file);
      }
    }

    if(event.currentTarget.name == 'linkDoc') {
      props.setFieldValue(props.name, event.currentTarget.value);
    }
  };

  const fileName =
    props.value instanceof File
      ? props.value.name
      : props.value?.startsWith('public/') 
        ? `${process.env.REACT_APP_API}/${props.value}`
        : props.value;

  return (
    <>
      <TextField
        label={props.label}
        required
        name="linkDoc"
        fullWidth
        onChange={handleChange}
        value={fileName ?? ''}
      />
      <label
        style={{
          cursor: 'pointer',
          color: '#01666b',
          marginTop: 5,
          display: 'inline-block'
        }}
      >
        <input
          id='file_field'
          style={{visibility: 'hidden', width: 0.1, height: 0.1}}
          accept="document/*"
          type="file"
          onInput={handleChange}
        />
        Subir documento
      </label>
    </>
  );
}
