import actionTypes from "constants/actionTypes";

const initialState = { feedback: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_FEEDBACK_LIST":
      return {
        ...state,
        feedback: action.payload.data
      };

    default:
      return state;
  }
};
