import actionTypes from "constants/actionTypes";

export const provinciasActions = {
  getProvincias,
  addProvincia,
  updateProvincia
};

function getProvincias(payload = {}) {
  return {
    type: actionTypes.GET_PROVINCIAS,
    payload
  };
}

function addProvincia(payload) {
  return {
    type: actionTypes.ADD_PROVINCIA,
    payload: payload
  };
}
function updateProvincia(payload) {
  return {
    type: actionTypes.UPDATE_PROVINCIA,
    payload: payload
  };
}
