import React, { useMemo, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Grid,
  Button,
  InputLabel,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Switch,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { recursosActions } from "actions/recursosActions";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AuthorsTable from "./components/AuthorsTable";
import ImageField from "./components/ImageField";
import FileField from "./components/FileField";
import { useStatus } from "helpers/custom-hooks.helper";
import { enqueueSnackbar } from "notistack";
import ContactInfo from "./components/ContactInfo";

const initialValue = {
  titulo: "",
  cuerpo: "",
  resourceType: "RESOURCE",
  description: "",
  autorlink: "",
  authors: [],
  descargalink: "",
  precio: null,
  claves: [],
  cursos: [],
  centerLogo: null,
  document: '',
  destacado: 0,
  activo: false,
};

const initBestPractice = {
  keydatas: '',
  targets: '',
  results: '',
  conclusions: '',
  recomendations: '',
}

const modalStyle = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`
};

const useStyles = makeStyles(theme => ({
  ckeditor: {
    '--ck-color-base-border': '#0006',
    '--ck-color-toolbar-border': '#0006',
    '--ck-border-radius': '5px',
    '& .ck-editor': {
      marginTop: theme.spacing(1)
    }
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const resourceType = {
  RESOURCE: 'Recurso',
  BEST_PRACTICE: 'Best Practice',
}

export const ContentForm = ({ rowSelected, setRowSelected, lang = "ES" }) => {
  const [form, setForm] = useStatus(initialValue);
  const [bestPractice, setBestPractice] = useStatus({ ...initBestPractice });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const coursesAll = useSelector(state => state.cursosReducer.cursos);
  const keysAll = useSelector(state => state.clavesReducer.claves);

  const { perspectives, materialTypes } = useSelector(
    state => state.recursosReducer
  );

  const classes = useStyles();

  const [courses, keys] = useMemo(() => {
    let courses = coursesAll.filter(item => item.active !== 1);
    let keys = keysAll.filter(item => item.active !== 1);

    keys = keys.map(({ id, titulo, num }) => ({ id, num, titulo }));
    courses = courses.map(({ id, curso }) => ({ id, curso }));

    if (rowSelected) {
      const tookCourses = rowSelected.cursos?.split(",").map(item => +item);
      const tookCoursesTitled = tookCourses
        ? courses.filter(item => tookCourses.includes(item.id))
        : [];

      const tookKeys = rowSelected.claves?.split(",")?.map(item => +item);
      const tookKeysTitled = tookKeys
        ? keys.filter(item => tookKeys.includes(item.num))
        : [];

      setBestPractice(rowSelected.bestPractice);
      setForm({
        ...initialValue,
        ...rowSelected,
        claves: tookKeysTitled,
        cursos: tookCoursesTitled
      });
    }

    return [courses, keys];
  }, [keysAll, coursesAll]);

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.value;

    if (event.currentTarget?.type === 'checkbox') {
      value = event.currentTarget.checked;
    } 

    let setter = setForm;
    if(bestPractice[name] !== undefined) {
      setter = setBestPractice;
    }

    setter({ [name]: value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await new Promise((resolve, reject) =>
        dispatch(
          recursosActions.deleteRecurso({
            resolve,
            reject,
            id: rowSelected.id,
            lang
          })
        )
      );
    } catch (err) {
      console.log(err);
    }

    setRowSelected();
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if(
      form.document &&
      typeof form.document === 'string' &&
      !form.document.startsWith('public/') && 
      !form.document.startsWith('http')
    ) {
      return enqueueSnackbar('Link del documento invalido.', { variant: 'error' });
    }

    try {
      setLoading(true);

      await new Promise((resolve, reject) => {
        const body = { resolve, reject, lang, ...form, bestPractice };

        if (rowSelected) dispatch(recursosActions.updateRecurso(body));
        else dispatch(recursosActions.createRecurso(body));
      });
    } catch (err) {
      console.log(err);
    }

    setRowSelected();
  };

  const onChangeField = (name, value) => {
    setForm({ [name]: value});
  }

  return (
    <form
      autoComplete="off"
      style={{ margin: 20 }}
      onSubmit={handleSubmit}
    >
      <div>
        <Grid style={{marginBottom: 3}} container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => setRowSelected()}
            > 
              Volver
            </Button>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  color="currentColor"
                  control={
                    <Switch
                      color="primary"
                      name="activo"
                      checked={Boolean(form.activo)}
                      onChange={handleChange}
                    />
                  }
                  label="Activo"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  color="currentColor"
                  control={
                    <Switch
                      color="primary"
                      name="destacado"
                      checked={Boolean(form.destacado)}
                      onChange={handleChange}
                    />
                  }
                  label="Destacado"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={9}>
            <TextField
              required
              label="Titulo"
              name="titulo"
              fullWidth
              value={form.titulo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth>
              <InputLabel>
                Tipo de recurso
              </InputLabel>
              <Select
                name="resourceType"
                label="Tipo de recurso"
                value={form.resourceType}
                onChange={handleChange}
              >
                {Object.keys(resourceType).map((item) => (
                  <MenuItem value={item} key={item}>
                    {resourceType[item]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              fullWidth
              options={courses}
              getOptionLabel={option => option.curso}
              value={form.cursos ?? []}
              onChange={(_, newValue) => {
                handleChange({
                  target: {
                    value: newValue,
                    name: "cursos"
                  }
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Cursos"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              fullWidth
              options={keys}
              getOptionLabel={option => String(option.titulo)}
              value={form.claves ?? []}
              onChange={(_, newValue) => {
                handleChange({
                  target: {
                    value: newValue,
                    name: "claves"
                  }
                });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Claves"
                  placeholder="Claves"
                />
              )}
            />
          </Grid>
          {form.resourceType === 'RESOURCE' && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>
                  Tipo de material
                </InputLabel>
                <Select
                  label="Tipo de material"
                  value={form.tipo ?? null}
                  onChange={handleChange}
                  name="tipo"
                >
                  <MenuItem value={-1}>Ningun tipo seleccionado</MenuItem>
                  {materialTypes.map((item, i) => (
                    <MenuItem value={item.id} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth >
              <InputLabel>
                Perspectiva
              </InputLabel>
              <Select
                name="perspectiva"
                label="Perspectiva"
                value={form.perspectiva ?? ""}
                onChange={handleChange}
              >
                <MenuItem value={-1}>Ninguna perspectiva seleccionada</MenuItem>
                {perspectives.map((item, i) => (
                  <MenuItem value={item.id} key={i}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {form.resourceType === 'RESOURCE'
              ? (
                <TextField
                  required
                  fullWidth
                  label="Link de descarga del curso"
                  name="descargalink"
                  value={form.descargalink}
                  onChange={handleChange}
                />
              ) : (
                <FileField
                  fullWidth
                  label="Link/nombre del documento"
                  name="document"
                  setFieldValue={onChangeField}
                  value={form.document}
                />
              )}
          </Grid>
          {form.resourceType === 'RESOURCE' && (
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Precio"
                type="number"
                name="precio"
                fullWidth
                value={form.precio ?? ""}
                onChange={handleChange}
              />
            </Grid>
          )}
          {form.resourceType === 'BEST_PRACTICE' && (
            <Grid item xs={12}>
              <Typography>
                Logo Centro
              </Typography>
              <ImageField
                value={form.centerLogo}
                name="centerLogo"
                setFieldValue={onChangeField}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              size="small"
              minRows={2}
              value={form.description}
              name='description'
              variant="outlined"
              onChange={handleChange}
              inputProps={{ maxLength: 1300 }}
              label="Descripcion"
            />
          </Grid>
          {form.resourceType === 'BEST_PRACTICE' && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  minRows={2}
                  value={bestPractice.keydatas}
                  name='keydatas'
                  onChange={handleChange}
                  inputProps={{ maxLength: 400 }}
                  variant="outlined"
                  label="Datos clave"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  size="small"
                  value={bestPractice.targets}
                  name='targets'
                  onChange={handleChange}
                  inputProps={{ maxLength: 500 }}
                  variant="outlined"
                  label="Objetivos"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  size="small"
                  value={bestPractice.results}
                  name='results'
                  onChange={handleChange}
                  inputProps={{ maxLength: 600 }}
                  variant="outlined"
                  label="Impacto y resultados"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  minRows={2}
                  value={bestPractice.recomendations}
                  name='recomendations'
                  onChange={handleChange}
                  inputProps={{ maxLength: 400 }}
                  variant="outlined"
                  label="¿Por qué se recomienda a otros centros hacerlo?"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  minRows={2}
                  value={bestPractice.conclusions}
                  name='conclusions'
                  onChange={handleChange}
                  inputProps={{ maxLength: 600 }}
                  variant="outlined"
                  label="Conclusión / Claves del éxito."
                />
              </Grid>
            </>
          )}
          <Grid item>
            <AuthorsTable
              datas={form.authors}
              onSave={(authors) => setForm({ authors })}
            />
          </Grid>
          {form.contact && (
            <Grid item>
              <ContactInfo datas={form.contact}/>
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="flex-end">
          {rowSelected && (
            <Button
              color="secondary"
              onClick={handleOpen}
              disabled={loading}
            >
              Eliminar Recurso
            </Button>
          )}
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={18} color="inherit" />
              }
            >
              Guardar Cambios
            </Button>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">Atencion!</h3>
          <p id="simple-modal-description">
            El recurso va a ser eliminado de la Base de Datos, ¿está seguro que
            desea eliminar la entrada?.
          </p>
          <Button
            variant="outlined"
            color="secondary"
            disabled={loading}
            onClick={handleDelete}
          >
            {loading ? "Eliminando..." : "Eliminar"}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={e => handleClose()}
          >
            Cancelar
          </Button>
        </div>
      </Modal>
    </form>
  );
};
