import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import * as _ from "lodash";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";

import { cargosActions } from "actions/cargosActions";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "14px 5px 14px 5px"
      }
    },
    MuiInput: {
      root: {
        fontSize: "13px"
      }
    }
  }
});

const columns = [
  { title: "id", field: "id", editable: "never" },
  { title: "Nombre", field: "nombre" }
];

export default function ModifyCargos() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const cargos = useSelector(state => state.cargosReducer.cargos);

  useEffect(() => {
    getInitalRequest();
  }, []);

  const getInitalRequest = async () => {
    try {
      setLoading(true);

      await new Promise((resolve, reject) =>
        dispatch(cargosActions.getCargos({ resolve, reject }))
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        {errorMessage && (
          <label style={{ color: "#e85353" }}>{errorMessage}</label>
        )}
        <MaterialTable
          title="Editar Cargos"
          columns={columns}
          data={cargos}
          isLoading={loading}
          options={{
            search: false
          }}
          editable={{
            onRowAdd: async newData => {
              var dataValues = _.values(newData);

              if (_.without(dataValues, "").length < 1) {
                setErrorMessage("Todos los campos deben estar completados");
              } else {
                setErrorMessage(null);
                dispatch(cargosActions.addCargo(newData));
              }
            },
            onRowUpdate: async newData => {
              var dataValues = _.values(newData);

              if (_.without(dataValues, "").length < 2) {
                setErrorMessage("Todos los campos deben estar completados");
              } else {
                setErrorMessage(null);

                dispatch(cargosActions.updateCargo(newData));
              }
            }
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}
