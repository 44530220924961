import React, { useEffect } from "react";

import MaterialTable from "material-table";
import * as _ from "lodash";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";

import CursosManagement from "../cursosManagement/cursosManagement.js";
import UserManagement from "../userManagement/UserManagement.js";

import { useDispatch, useSelector } from "react-redux";

import { centrosActions } from "actions/centrosActions";
import { Grid, Typography } from "@material-ui/core";

export const centerTypesMap = {
  '': "Ninguno",
  'CEPA': "Centro de educación para adultos (CEPA)",
  'FP': "Centro exclusivamente de Formación Profesional (FP)",
};

export const ownershipMap = {
  'PUBLIC': "Público",
  'CONCERTED': "Concertado",
  'PRIVATE': "Privado",
};

export default function ModifyCentros(props) {
  const loading = useSelector(state => state.loading.loading);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [state, setState] = React.useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.municipios && props.provincias) {
      var municipios = props.municipios; //no puedo usar props.profiles dentro de la material-table porque haria referencia a los propios PROS del componente y retorna un undefined
      var provincias = props.provincias; //no puedo usar props.profiles dentro de la material-table porque haria referencia a los propios PROS del componente y retorna un undefined
      var comunidades = props.comunidades;
      var centros = props.centros;
      // var munList = props.munList;

      let provinciasJSON = {};
      provincias.map(item => {
        provinciasJSON[item.id_provincia] = item.provincia;
      });
      let municipiosJSON = {};
      municipios.map(item => {
        municipiosJSON[item.id_municipio] = { nombre: item.nombre };
      });
      let municipiosJSON2 = {};
      municipios.map(item => {
        municipiosJSON2[item.id_municipio] = item.nombre;
      });
      let comunidadesJSON = {};
      comunidades.map(item => {
        comunidadesJSON[item.id] = item.nombre;
      });

      setState({
        columns: [
          { title: "ID", field: "id", editable: "never" },
          { title: "Nombre", field: "nombre" },
          { title: "Direccion", field: "direccion" },
          { title: "Tipo", field: "kind", lookup: centerTypesMap },
          { title: "Titularidad", field: "ownership", lookup: ownershipMap },

          {
            title: "Comunidad",
            field: "comunidades_id",
            lookup: comunidadesJSON
          },
          {
            title: "Provincia",
            field: "provincias_id_provincia",
            lookup: provinciasJSON,
          },
          {
            title: "Municipio",
            field: "municipios_id_municipio",
            lookup: municipiosJSON2,
            editComponent: props => {
              let filteredList = [];
              const provinceId = props.rowData?.provincias_id_provincia; 
              if(provinceId) {
                filteredList = municipios.filter(item => item.id_provincia === Number(provinceId));
              }

              return (
                <Autocomplete
                  id="municipio"
                  options={filteredList}
                  getOptionLabel={option => option.nombre}
                  style={{ width: 170, heigh: 70 }}
                  defaultValue={municipiosJSON[props.value]}
                  onChange={(event, value) => {
                    props.onChange(value.id_municipio);
                  }}
                  className="fontSame"
                  renderInput={params => (
                    <TextField {...params} margin="none" />
                  )}
                />
              );
            }
          },
          { title: "C.P", field: "cp" },
          { title: "Código", field: "codigo" },
          { title: "Grupo", field: "groupby" },
          {
            title: "Activa",
            field: "activo",
            lookup: { 1: "Activado", 0: "Desactivado" }
          },
          { title: "Home", field: "home" }
        ],
        data: centros
      });
    }
  }, [props.centros, props.provincias, props.munSelected]);

  return (
    <div>
      {errorMessage && (
        <label style={{ color: "#e85353" }}>{errorMessage}</label>
      )}
      <MaterialTable
        title={
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">Editar Centros</Typography>
            </Grid>
          </Grid>
        }
        columns={state.columns}
        data={state.data}
        isLoading={loading}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 50],
          addRowPosition: "first",
          detailPanelType: "single"
        }}
        detailPanel={[
          {
            tooltip: "Mostrar Cursos",
            render: rowData => {
              return (
                <Grid container spacing={2} style={{ paddingTop: 15 }}>
                  <Grid item xs={3}>
                    <CursosManagement
                      centroSeleccionado={rowData.id}
                      infoAmpliadaCentro={true}
                    ></CursosManagement>
                  </Grid>
                  <Grid item xs={9}>
                    <UserManagement centro={rowData.id}></UserManagement>
                  </Grid>
                </Grid>
              );
            }
          }
        ]}
        editable={{
          onRowAdd: async (newData) => {
            if (
               ["", undefined, null, " "].includes(newData.nombre) ||
              newData.nombre.length < 2 ||
              ["", undefined, null].includes(newData.municipios_id_municipio) ||
              ["", undefined, null].includes(newData.provincias_id_provincia) ||
              ["", undefined, null].includes(newData.codigo) ||
              ["", undefined, null].includes(newData.activo) ||
              ["", undefined, null].includes(newData.comunidades_id)
            ) {
              setErrorMessage("Todos los campos deben estar rellenados");
            } else {
              setErrorMessage(null);
              dispatch(centrosActions.createCentro(newData));
            }
          },
          onRowUpdate: async (newData) => {
            if (
               ["", undefined, null, " "].includes(newData.nombre) ||
              newData.nombre.length < 2 ||
              ["", undefined, null].includes(newData.municipios_id_municipio) ||
              ["", undefined, null].includes(newData.provincias_id_provincia) ||
              ["", undefined, null].includes(newData.codigo) ||
              ["", undefined, null].includes(newData.activo) ||
              ["", undefined, null].includes(newData.comunidades_id)
            ) {
              setErrorMessage("Todos los campos deben estar rellenados")
            } else {
              setErrorMessage(null);
              dispatch(centrosActions.updateCentro(newData));
            }
          }
        }}
      />
    </div>
  );
}
