import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingOverlay } from "../../components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import { fetchResultsKeys } from "../../actions/clavesActions";
import { DescriptionOutlined } from "@material-ui/icons";
import { ReportBody } from "./components/ReportBody";
import html2canvas from "html2canvas";
import { dowloadSimplePdfReport } from "actions/centrosActions";
import { CenterDashboardFilter } from "views/CentersDashboard/components/CenterDashboardFilter";

const useStyles = makeStyles({
  nodeForDownload: {
    position: "fixed",
    zIndex: -10,
    top: 0,
    left: 0,
    width: 1200
  }
});

export default function CentersResultsByKey() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const graph = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    initialRequest();
  }, []);

  const initialRequest = async () => {
    try {
      setLoading(true);
      await getResultsKeys();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getResultsKeys = filter => {
    return new Promise((resolve, reject) =>
      dispatch(
        fetchResultsKeys({
          reject,
          resolve,
          filter
        })
      )
    );
  };

  const handleFilter = async filter => {
    try {
      setLoading(true);
      await getResultsKeys(filter);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);

      const canvas = await html2canvas(graph.current);
      const image = canvas.toDataURL();

      await new Promise((resolve, reject) =>
        dispatch(
          dowloadSimplePdfReport({
            reject,
            resolve,
            image,
            title: "RESULTADOS XCELENCE POR CLAVE"
          })
        )
      );
    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay isLoading={loading} />
      <Grid container className="my-3" justifyContent="space-between">
        <Box width={185}></Box>
        <Grid item>
          <Typography variant="h6" align="center">
            RESULTADOS XCELENCE POR CLAVE
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="outlined"
            color="default"
            disabled={downloading}
            onClick={handleDownload}
            startIcon={
              downloading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                <DescriptionOutlined />
              )
            }
          >
            Exportar Grafica
          </Button>
        </Grid>
      </Grid>
      <ReportBody />
      <Grid container className="mt-4">
        <Grid item xs={12}>
          <CenterDashboardFilter onFilter={handleFilter} />
        </Grid>
      </Grid>
      <div className={classes.nodeForDownload} ref={graph}>
        <ReportBody />
      </div>
    </div>
  );
}
