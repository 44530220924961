import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import countReducer from "reducers/countReducer.js";
import userReducer from "reducers/userReducer.js";
import provinciasReducer from "reducers/provinciasReducer.js";
import municipiosReducer from "reducers/municipiosReducer.js";
import comunidadesReducer from "reducers/comunidadesReducer.js";
import centrosReducer from "reducers/centrosReducer.js";
import cargosReducer from "reducers/cargosReducer.js";
import cursosReducer from "reducers/cursosReducer.js";
import clavesReducer from "reducers/clavesReducer.js";
import contenidosReducer from "reducers/contenidosReducer.js";
import messageReducer from "reducers/messageReducer.js";
import feedbackReducer from "reducers/feedbackReducer.js";
import recursosReducer from "reducers/recursosReducer.js";
import toolkitsReducer from "reducers/toolkitsReducer.js";
import { settingsReducer, reportsReducer } from "./reducers";

import loading from "reducers/loading.js";

import createSagaMiddleware from "redux-saga";
import root_saga from "sagas/root_saga";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;
    else {
      // dispatch(userActions.setFullInfo(localStorage.getItem("userDetails")))
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const persistedStore = loadState();

const initialiseSagaMiddleware = createSagaMiddleware();
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    persistedStore,
    countReducer,
    userReducer,
    provinciasReducer,
    municipiosReducer,
    comunidadesReducer,
    messageReducer,
    centrosReducer,
    loading,
    cargosReducer,
    cursosReducer,
    clavesReducer,
    contenidosReducer,
    feedbackReducer,
    recursosReducer,
    toolkitsReducer,
    settingsReducer,
    reportsReducer
  }),
  storeEnhancers(applyMiddleware(initialiseSagaMiddleware))
);
initialiseSagaMiddleware.run(root_saga);
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
