import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CSVReader from "react-csv-reader";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "actions/userActions";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};
export default function MasivoManagement() {
  const dispatch = useDispatch();
  var apiDONE = useSelector(state => state.loading.APIactions);

  var altamasivo = useSelector(state => state.userReducer.altamasivo);

  const [salida, setSalida] = React.useState([]);
  const [temp, setTemp] = useState(0);

  useEffect(() => {
    console.log(apiDONE);
  }, [apiDONE]);

  useEffect(() => {
    console.log("miguelllll");
    if (altamasivo) {
      console.log(altamasivo);
      var mTemp = salida;

      mTemp.push({ altamasivo });
      setSalida(mTemp);
      setTemp(temp + 1);
    }
  }, [altamasivo]);

  const handleForce = (data, fileName) => {
    data.map(item => {
      dispatch(
        userActions.altaMasiva(
          item.nombre,
          item.apellidos,
          item.email,
          item.tipo,
          item.codigocentro,
          item.grupo
        )
      );
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h2>Alta masiva de usuarios</h2>
        <CSVReader
          label="Selecciona el CSV para subir usuarios"
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
        />

        <div>Resultado:</div>
        {salida.map(item => {
          return <div>{item.altamasivo}</div>;
        })}
      </GridItem>
    </GridContainer>
  );
}
