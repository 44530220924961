import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherComunidades() {
  yield takeEvery(actionTypes.GET_COMUNIDADES, getComunidades);
}

function* getComunidades() {
  try {
    let apiEndpoint = "/v5/comunidades";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_COMUNIDADES_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
