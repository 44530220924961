import { SAVE_PERIODS, SAVE_SENT_EMAILS } from "../constants/settings.constant";

const initialState = {
  periods: [],
  activePeriod: {
    id: 0,
    periodo: '',
  },
  sentEmails: [],
  totalSentEmails: 0
};

export const settingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_PERIODS:
      const periods = action.payload ?? [];
      
      return {
        ...state,
        periods,
        activePeriod: periods.find(el => el.activo),
      };
    case SAVE_SENT_EMAILS:
      return {
        ...state,
        sentEmails: action.payload.data,
        totalSentEmails: action.payload.count
      };
    default:
      return state;
  }
};
