import { takeEvery, call, put } from "redux-saga/effects";
import { xcService } from "../services";

import { savePeriods, saveSentEmails } from "actions/settings.action";

import {
  FETCH_PERIODS,
  FETCH_SENT_EMAILS
} from "../constants/settings.constant";
import { buildQueryParams } from "helpers/uri-parser.helper";

export function* settingsSaga() {
  yield takeEvery(FETCH_PERIODS, fetchPeriods);
  yield takeEvery(FETCH_SENT_EMAILS, fetchSentEmails);
}

function* fetchPeriods() {
  try {
    let apiEndpoint = "/v7/allperiodos";

    const resp = yield call(xcService.get, apiEndpoint);

    if (resp) yield put(savePeriods(resp.data));
  } catch (error) {
    console.log(error);
  }
}

function* fetchSentEmails({ payload }) {
  let { resolve, reject, setup, filter } = payload;

  try {
    const endpoint = "/v15/sent-emails";
    const uri = endpoint + buildQueryParams(filter, setup, "sent_email");

    const { data } = yield call(xcService.get, uri);

    if (data) {
      yield put(saveSentEmails(data));
    }

    resolve();
  } catch (error) {
    reject(error);
  }
}
