import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOverlay } from "../../components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
import {
  dowloadSimplePdfReport,
  fetchEvaluationData
} from "../../actions/centrosActions";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { DescriptionOutlined } from "@material-ui/icons";
import html2canvas from "html2canvas";
import { CenterDashboardFilter } from "views/CentersDashboard/components/CenterDashboardFilter";

export default function EvaluationByPeriod() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { evaluationsByPeriod } = useSelector(state => state.centrosReducer);
  const graph = useRef(null);

  useEffect(() => {
    initialRequest();
  }, []);

  const initialRequest = async () => {
    try {
      setLoading(true);

      await getEvaluationData();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleFilter = async filter => {
    try {
      setLoading(true);
      await getEvaluationData(filter);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getEvaluationData = filter => {
    return new Promise((resolve, reject) =>
      dispatch(
        fetchEvaluationData({
          reject,
          resolve,
          filter
        })
      )
    );
  };

  const handleDownload = async () => {
    try {
      const canvas = await html2canvas(graph.current.container);
      const image = canvas.toDataURL();

      setDownloading(true);

      await new Promise((resolve, reject) =>
        dispatch(
          dowloadSimplePdfReport({
            reject,
            resolve,
            image,
            title: "EVALUACIONES TERMINADAS POR PERIODO"
          })
        )
      );
    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  };

  return (
    <div style={{ position: "relative", backgroundColor: "#fff" }}>
      <LoadingOverlay isLoading={loading} />
      <Grid container>
        <Grid
          xs={12}
          item
          container
          className="my-3"
          justifyContent="space-between"
        >
          <Box width={185}></Box>
          <Grid item>
            <Typography variant="h6" align="center">
              EVALUACIONES TERMINADAS POR PERIODO
            </Typography>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              color="default"
              onClick={handleDownload}
              disabled={downloading}
              startIcon={
                downloading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  <DescriptionOutlined />
                )
              }
            >
              Exportar Grafica
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mb-5">
          <LineChart
            className="mx-auto"
            width={600}
            height={400}
            data={evaluationsByPeriod}
          >
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
            <XAxis dataKey="periodo" padding={{ left: 40, right: 40 }} />
            <YAxis padding={{ top: 40 }} />
            <Tooltip />
            <Legend />
            <Line dataKey="Terminadas" stroke="#88d98e" fill="#88d98e" />
            <Line dataKey="Empezadas" stroke="#febe00" fill="#febe00" />
          </LineChart>
        </Grid>

        <LineChart
          style={{ position: "fixed", zIndex: -10, top: 0, left: 0 }}
          width={1200}
          height={800}
          data={evaluationsByPeriod}
          ref={graph}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.8} />
          <XAxis dataKey="periodo" padding={{ left: 40, right: 40 }} />
          <YAxis padding={{ top: 40 }} />
          <Legend />
          <Line
            isAnimationActive={false}
            dataKey="Terminadas"
            stroke="#88d98e"
            fill="#88d98e"
          />
          <Line
            isAnimationActive={false}
            dataKey="Empezadas"
            stroke="#febe00"
            fill="#febe00"
          />
        </LineChart>

        <Grid item xs={12}>
          <CenterDashboardFilter onFilter={handleFilter} />
        </Grid>
      </Grid>
    </div>
  );
}
