import { SAVE_RESULTS_KEYS_REPORT } from "../constants/keys.constant";

const initialState = {
  claves: [],
  colores: null,
  selectedClave: -1,
  listaRespuestasActual: [],
  tiposPreguntas: [],
  preguntas: [],
  showInactives: false,
  showInactivesPreguntas: false,
  showInactivesTipos: false,
  resultsKeys: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RESULTS_KEYS_REPORT:
      return {
        ...state,
        resultsKeys: action.payload
      };
    case "SET_CLAVES_LIST":
      return {
        ...state,
        claves: action.payload.data
      };

    case "SET_PREGUNTAS_LIST":
      return {
        ...state,
        preguntas: action.payload.data
      };
    case "SET_COLORES_LIST":
      return {
        ...state,
        colores: action.payload.data
      };
    case "SET_SHOW_INACTIVES_CLAVE":
      return {
        ...state,
        showInactives: action.payload
      };
    case "SET_SHOW_INACTIVES_TIPOS":
      return {
        ...state,
        showInactivesTipos: action.payload
      };

    case "SET_SHOW_INACTIVES_PREGUNTAS":
      return {
        ...state,
        showInactivesPreguntas: action.payload
      };

    case "SET_CLAVE_SELECTED":
      return {
        ...state,
        selectedCentro: action.payload
      };

    case "SET_RESPUESTAS_BY_PREGUNTA":
      return {
        ...state,
        listaRespuestasActual: action.payload
      };
    case "SET_COLORES_LIST":
      return {
        ...state,
        colores: action.payload
      };
    case "SET_TIPOS_LIST":
      return {
        ...state,
        tiposPreguntas: action.payload.data
      };
    case "CHANGE_CLAVE_SELECTED":
      return {
        ...state,
        selectedClave: action.payload
      };

    default:
      return state;
  }
};
