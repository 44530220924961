import { takeEvery, call, put, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { feedbackActions } from "actions/feedbackActions";

export default function* watcherFeedback() {
  yield takeEvery(actionTypes.GET_FEEDBACK, getFeedbacks);
  yield takeEvery(actionTypes.UPDATE_FEEDBACK, updateFeedback);
  yield takeEvery(actionTypes.CREATE_FEEDBACK, createFeedback);
}

function* getFeedbacks({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v3/feedbacks";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res)
      yield all([
        put({ type: "SET_FEEDBACK_LIST", payload: res }),
        call(resolve, undefined)
      ]);
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateFeedback({ payload }) {
  const { resolve, reject, lang, ...feetback } = payload;

  try {
    let apiEndpoint = "/v3/feedback/" + payload.id;

    const res = yield call(xcService.put, apiEndpoint, feetback, lang);

    if (res) yield put(feedbackActions.getFeedbacks({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}

function* createFeedback({ payload }) {
  const { resolve, reject, lang, ...feetback } = payload;

  try {
    let apiEndpoint = "/v3/feedbacks";

    const res = yield call(xcService.postToken, apiEndpoint, feetback, lang);

    if (res) yield put(feedbackActions.getFeedbacks({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}
