import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModifyUsers from "./ModifyUsers.js";
import ModifyProfiles from "./ModifyProfiles.js";
import ModifyCargos from "./ModifyCargos.js";

import { userActions } from "actions/userActions";
import { centrosActions } from "actions/centrosActions";
import ModifyUsersAmpliadoCentro from "./ModifyUsersAmpliadoCentro.js";
import { Grid, Paper } from "@material-ui/core";
import MasivoManagement from "views/MasivoManagement.js";

export default function UserManagement(props) {
  const dispatch = useDispatch();
  var users = useSelector(state => state.userReducer.users);
  var centros = useSelector(state => state.centrosReducer.centros);

  var apiDONE = useSelector(state => state.loading.APIactions);

  var usersSeleccionados = useSelector(
    state => state.userReducer.usersSeleccionados
  );

  var resetData = useSelector(state => state.userReducer.resetData);

  useEffect(() => {
    if (resetData)
      alert(
        "Se ha reseteado la contraseña del usuario y se le ha enviado un mail"
      );
  }, [resetData]);

  var newuserData = useSelector(state => state.userReducer.newuserData);

  useEffect(() => {
    if (newuserData) {
      alert("Se ha creado el usuario y se le ha enviado un mail");
      recarga();
    }
  }, [newuserData]);

  var newUserError = useSelector(state => state.userReducer.newUserError);

  useEffect(() => {
    if (newUserError) {
      alert("Se ha producido un error al crear el usuario");
      alert(newUserError);
    }
  }, [newUserError]);

  useEffect(() => {
    dispatch(userActions.getUsers());

    if (!centros.lenght && !props.centro) {
      dispatch(centrosActions.getCentros());
    }
  }, [apiDONE]);

  const recarga = () => {
    if (props.centro) {
      dispatch(userActions.getUsersCentros({ id: props.centro }));
    }
  };

  useEffect(() => {
    recarga();
  }, [props.centro]);

  return (
    <Grid container spacing={2}>
      {users && centros && !props.centro && (
        <Grid item xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <ModifyUsers users={users} centros={centros} />
            </GridItem>
          </GridContainer>
        </Grid>
      )}
      {!props.centro && (
        <Grid item xs={12}>
          <GridContainer>
            <GridItem xs={6}>
              <ModifyProfiles />
            </GridItem>
            <GridItem xs={6}>
              <ModifyCargos />
            </GridItem>
          </GridContainer>
        </Grid>
      )}
      {usersSeleccionados && props.centro && (
        <Grid item xs={12}>
          <ModifyUsersAmpliadoCentro
            users={usersSeleccionados}
            centro={props.centro}
          />
        </Grid>
      )}
      {!props.centro && (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 10 }}>
            <MasivoManagement />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
