import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOverlay } from "../../components";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { ShowKeyAnswers, QuizInfoFilter } from "./components";
import {
  dowloadCenterInfo,
  fetchCenterQuizFilter,
  fetchCenterQuizInfo,
  fetchCenterQuizColumns,
  putCenterQuizColumn
} from "../../actions/centrosActions";
import { columnsBuilder } from "./center-quiz-columns";
import { LocalStorageService, xcService } from "../../services";

const initialSetup = {
  size: 5,
  page: 0,
  order: [],
  ...LocalStorageService.get("center_datas_setup")
};

export default function CenterTestInfo() {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [targetKey, setTargetKey] = useState(null);
  const [setup, setSetup] = useState(initialSetup);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  const { quizCenterInfo, count } = useSelector(state => state.centrosReducer);
  const { activePeriod } = useSelector(store => store.settingsReducer);

  useEffect(() => {
    setLoading(true);

    if(activePeriod.id) {
      initialRequest();
    }
  }, [activePeriod.id]);

  const handleResetQuiz = async (centerId) => {
    try {
      setLoading(true);

      const apiEndpoint = '/v7/reset-quiz';

      await xcService.post(apiEndpoint, {centerId});

      await getCenterQuizInfo();

    } catch(error) {
      console.log(error);
    }

    setLoading(false);
  }

  const initialRequest = async () => {
    try {
      await getCenterQuizInfo();

      await new Promise((resolve, reject) =>
        dispatch(fetchCenterQuizFilter({ reject, resolve }))
      );

      const columnsConfig = await new Promise((resolve, reject) =>
        dispatch(
          fetchCenterQuizColumns({
            reject,
            resolve,
            table: "center_quiz"
          })
        )
      );

      const columns = columnsBuilder(
        setTargetKey,
        handleResetQuiz,
        activePeriod,
        columnsConfig
      );

      setColumns(columns);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleFilter = async curretSetup => {
    if (!curretSetup) curretSetup = { ...setup, page: 0 };

    try {
      setLoading(true);

      await getCenterQuizInfo(curretSetup);
    } catch (error) {
      console.log(error);
    }

    setSetup(curretSetup);

    setLoading(false);
  };

  const getCenterQuizInfo = (localSetup = setup) => {
    return new Promise((resolve, reject) =>
      dispatch(
        fetchCenterQuizInfo({
          reject,
          resolve,
          setup: localSetup
        })
      )
    );
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);

      await new Promise((resolve, reject) =>
        dispatch(
          dowloadCenterInfo({
            reject,
            resolve,
            setup
          })
        )
      );
    } catch (error) {
      console.log(error);
    }

    setDownloading(false);
  };

  const handleColumnVisibility = (column, action) => {
    const target = columns.find(item => item.name === column);
    const visible = action === "add";

    if (target?.options) target.options.display = visible;

    dispatch(
      putCenterQuizColumn({
        table: "center_quiz",
        visible,
        column
      })
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <LoadingOverlay isLoading={loading} />
        <QuizInfoFilter className="mb-4" onFilter={handleFilter} />
        <MUIDataTable
          title="Lista de centros"
          data={quizCenterInfo}
          columns={columns}
          options={{
            serverSide: true,
            print: false,
            search: false,
            responsive: "vertical",
            download: false,
            filter: false,
            count,
            page: setup.page,
            rowsPerPage: setup.size,
            rowsPerPageOptions: [5, 10, 20],
            sortOrder: { direction: setup.order[1], name: setup.order[0] },
            expandableRows: false,
            selectableRows: "none",
            customToolbar: () => (
              <>
                <IconButton
                  title="Descargar"
                  onClick={handleDownload}
                  disabled={downloading}
                >
                  {downloading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <CloudDownload />
                  )}
                </IconButton>
              </>
            ),
            textLabels: {
              body: { noMatch: "😥 Sin centros que mostrar." },
              pagination: { rowsPerPage: "Filas por pagina" }
            },
            onChangeRowsPerPage: size => {
              handleFilter({ ...setup, size });
            },
            onViewColumnsChange: handleColumnVisibility,
            onChangePage: currentPage => {
              handleFilter({
                ...setup,
                page: currentPage
              });
            },
            onColumnSortChange: (column, direction) => {
              handleFilter({
                ...setup,
                order: direction !== "none" ? [column, direction] : []
              });
            }
          }}
        />
      </Grid>
      <ShowKeyAnswers targetKey={targetKey} setTargetKey={setTargetKey} />
    </Grid>
  );
}
