import { takeEvery, call, put, select, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { clavesActions } from "actions/clavesActions";
import { FETCH_RESULTS_KEYS } from "../constants/keys.constant";
import { saveResultsKeys } from "../actions/clavesActions";
import { buildQueryParams } from "helpers/uri-parser.helper";

export default function* watcherClaves() {
  yield takeEvery(actionTypes.GET_CLAVES, getClaves);
  yield takeEvery(actionTypes.GET_PREGUNTAS, getPreguntas);

  yield takeEvery(actionTypes.GET_COLORES, getColores);
  yield takeEvery(actionTypes.GET_TIPOS, getTipos);

  yield takeEvery(actionTypes.CREATE_CLAVE, createClave);
  yield takeEvery(actionTypes.CREATE_PREGUNTA, createPregunta);

  yield takeEvery(actionTypes.CREATE_TIPO, createTipo);

  yield takeEvery(actionTypes.UPDATE_CLAVE, updateClave);
  yield takeEvery(actionTypes.UPDATE_PREGUNTA, updatePregunta);

  yield takeEvery(actionTypes.UPDATE_TIPO, updateTipo);

  yield takeEvery(actionTypes.GET_RESPUESTAS, getRespuestas);
  yield takeEvery(actionTypes.UPDATE_RESPUESTAS, updateRespuestas);
  yield takeEvery(actionTypes.CREATE_RESPUESTAS, createRespuestas);

  yield takeEvery(FETCH_RESULTS_KEYS, fetchResultsKeys);
}
export const getClavesList = state => state.clavesReducer.claves;

function* fetchResultsKeys({ payload }) {
  const { resolve, reject, filter } = payload;

  try {
    const uri = "/v3/results-keys-report";
    const path = uri + buildQueryParams(filter);

    const res = yield call(xcService.get, path);

    if (res) yield put(saveResultsKeys(res.data));

    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  }
}

function* getClaves({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v3/claves";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res) yield put({ type: "SET_CLAVES_LIST", payload: res });

    if (resolve) yield call(resolve, undefined);
  } catch (e) {
    if (reject) yield call(reject, "Interal Error");
  }
}

function* getPreguntas({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v3/preguntas";
    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res) yield put({ type: "SET_PREGUNTAS_LIST", payload: res });

    if (resolve) yield call(resolve, undefined);
  } catch (e) {
    if (reject) yield call(reject, "Interal Error");
  }
}

function* createClave({ payload }) {
  const { lang, resolve, reject, ...key } = payload;

  try {
    let apiEndpoint = "/v3/claves";

    const res = yield call(xcService.postToken, apiEndpoint, key, lang);

    if (res) yield put(clavesActions.getClaves({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* createPregunta({ payload }) {
  const { lang, resolve, reject, ...question } = payload;

  try {
    let apiEndpoint = "/v3/preguntas";

    const res = yield call(xcService.postToken, apiEndpoint, question, lang);

    if (res) yield put(clavesActions.getPreguntas({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* createTipo({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v3/tipos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* updateClave({ payload }) {
  const { resolve, reject, lang, ...key } = payload;

  try {
    let apiEndpoint = "/v3/clave/" + payload.id;

    const res = yield call(xcService.put, apiEndpoint, key, lang);

    if (res) yield put(clavesActions.getClaves({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updatePregunta({ payload }) {
  const { resolve, reject, lang, ...question } = payload;

  try {
    let apiEndpoint = "/v3/pregunta/" + payload.id;

    const res = yield call(xcService.put, apiEndpoint, question, lang);

    if (res) yield put(clavesActions.getPreguntas({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateTipo({ payload }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v3/tipo/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.put(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getColores() {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v3/colores";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_COLORES_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getTipos() {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v3/tipos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_TIPOS_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getRespuestas({ payload }) {
  const { id, resolve, reject, lang } = payload;

  try {
    let apiEndpoint = `/v3/preguntas/${id}/respuestas`;

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res)
      yield all([
        put({
          type: actionTypes.SET_RESPUESTAS_BY_PREGUNTA,
          payload: res.data
        }),
        call(resolve, undefined)
      ]);
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateRespuestas({ payload }) {
  const { resolve, reject, lang, ...answer } = payload;

  try {
    let apiEndpoint = "/v3/respuesta/" + answer.id;

    const res = yield call(xcService.put, apiEndpoint, answer, lang);

    if (res)
      yield put(
        clavesActions.getRespuestas({
          resolve,
          reject,
          lang,
          id: answer.preguntas_id
        })
      );
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* createRespuestas({ payload }) {
  const { lang, resolve, reject, ...answer } = payload;

  try {
    let apiEndpoint = "/v3/respuestas";

    const res = yield call(xcService.postToken, apiEndpoint, answer, lang);

    if (res)
      yield put(
        clavesActions.getRespuestas({
          resolve,
          reject,
          lang,
          id: answer.preguntas_id
        })
      );
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}
