import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    borderRadius: 20,
    width: "100%",
    border: "1px solid #bdbdbd",
    overflow: "hidden",
    "& > span": {
      height: 40,
      minWith: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#bdbdbd",
      flexGrow: 1,
      borderRight: "1px solid #bdbdbd",
      "&:last-child": {
        borderRightWidth: 0
      },
      "&.selected": {
        color: "#fff",
        backgroundColor: "#bdbdbd"
      }
    }
  }
});

export function RatingAnswer({ answers }) {
  const classes = useStyles();
  const resp = answers.find(item => item.centerAnswer);

  return (
    <div className={classes.root}>
      {answers.map(({ respuesta, id }, i) => (
        <span key={i} className={id <= resp?.id ? "selected" : ""}>
          {respuesta}
        </span>
      ))}
    </div>
  );
}
