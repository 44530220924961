import React from "react";
import { SwChip } from "../../components";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";

export const columnsBuilder = (
  setTargetKey = () => { },
  handleResetQuiz = () => { },
  activePeriod = {},
  config = []
) => {
  const baseColumn = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded"
      }
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "ccaa",
      label: "Comunidad Autónoma",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "municipality",
      label: "Municipio",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "groupby",
      label: "Grupo",
      options: {
        sortThirdClickReset: true,
        customBodyRender: value => value ?? "Sin grupo"
      }
    },
    {
      name: "ownership",
      label: "Titularidad",
      options: {
        sortThirdClickReset: true
      }
    },
    {
      name: "quiz",
      label: "Evaluación",
      options: {
        sortThirdClickReset: true,
        customBodyRender: value => value ?? "Sin evaluación"
      }
    },
    {
      name: "send",
      label: "Finalización",
      options: {
        sortThirdClickReset: true,
        customBodyRender: value =>
          value ? moment(value).format("DD/MM/YYYY HH:ss") : "Sin fecha"
      }
    },
    {
      name: "status",
      label: "Estado",
      options: {
        sort: false,
        customBodyRender: (value, { rowData }) => {
          let response = (
            <SwChip color="error" className="px-2">
              Sin comenzar
            </SwChip>
          );

          if (value === "FINISHED")
            response = (
              <SwChip color="success" className="px-2">
                Completada
              </SwChip>
            );
          else if (value === "IN_PROGRESS")
            response = (
              <SwChip color="warning" className="px-2">
                En proceso
              </SwChip>
            );
          else if (value === "NOT_FINISHED") {
            const lastItem = rowData[8]?.lastAnswer;

            response = (
              <SwChip color="warning" className="p-2">
                No terminada
                <br />
                <b>{moment(lastItem).format("DD/MM/YYYY")}</b>
              </SwChip>
            );
          }

          return response;
        }
      }
    },
    {
      name: "keys",
      label: "Claves",
      options: {
        sort: false,
        customBodyRender: (value, { rowData }) =>
          value.keys.length ? (
            <Grid container>
              {value.keys.map(({ keyId, num, percentage }, i) => (
                <Grid item key={i} className="mt-1 mb-2 mr-1">
                  {percentage === 100 ? (
                    <SwChip color="success">
                      Clave {num}: <b>Completada</b>
                    </SwChip>
                  ) : (
                    <SwChip
                      color="warning"
                      onCLick={() =>
                        setTargetKey({
                          keyId,
                          num,
                          centerId: rowData[0],
                          centerName: rowData[1],
                          period: value.label,
                          periodId: value.id
                        })
                      }
                    >
                      Clave {num}: <b>Al {percentage}%</b>
                    </SwChip>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container>
              <SwChip color="error">
                <b>Sin respuestas</b>
              </SwChip>
            </Grid>
          )
      }
    },
    {
      name: "periodo",
      label: "Acciones",
      options: {
        sort: false,
        customBodyRender: (value, {rowData}) => {
          let response = null;

          if (value.status === "FINISHED" && value.id === activePeriod.id)
            response = (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={handleResetQuiz.bind(null, rowData[0])}>
                Reiniciar evaluación
              </Button>
            );

          return response;
        }
      }
    },
  ];

  baseColumn.forEach(item => {
    if (!item.options.display) {
      const visibility = config.find(({ column }) => column === item.name);

      item.options.display = Boolean(visibility?.visible ?? true);
    }
  });

  return baseColumn;
};
