import { takeEvery, call, put, select, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";

export default function* watcherUser() {
  yield takeEvery(actionTypes.GET_CARGOS, getCargos);
  yield takeEvery(actionTypes.CREATE_USER, createUser);
  yield takeEvery(actionTypes.GET_USERS, getUsers);
  yield takeEvery(actionTypes.UPDATE_USER, updateUser);
  yield takeEvery(actionTypes.GET_PROFILES, getProfiles);
  yield takeEvery(actionTypes.GET_LANGUAGES, getLanguages);
  yield takeEvery(actionTypes.DELETE_USER, deleteUser);
  yield takeEvery(actionTypes.UPDATE_PROFIL, updateProfil);
  yield takeEvery(actionTypes.ADD_PROFILE, addProfile);
  yield takeEvery(actionTypes.GET_USERS_CENTRO, getUsersCentro);
  yield takeEvery(actionTypes.ALTA_MASIVA, altaMasiva);
  yield takeEvery(actionTypes.RESET_PASSWORD, resetPassword);
  yield takeEvery(actionTypes.NEW_USER, newUser);
}
export const getProfileList = state => state.userReducer.profiles;

function* newUser({ payload }) {
  try {
    let apiEndpoint = "/v1/auth/registeruser";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      console.log(res);
      yield put({ type: "NEW_USER_DATA", payload: res });

      yield put({ type: "DEL_NEW_USER", payload: res });
    }
  } catch (e) {
    yield put({ type: "NEW_USER_ERROR", payload: e });
    yield put({ type: "DEL_NEW_USER", payload: e });
  }
  // yield put({ type: "END_REQUEST" })
}

function* resetPassword(payload) {
  try {
    let apiEndpoint = "/v1/auth/reset/" + payload.payload;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.patch(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      console.log(res);
      yield put({ type: "RESET_PASSWORD_DATA", payload: res });

      yield put({ type: "DELE_RESET_PASSWORD_DATA", payload: res });
    }
  } catch (e) {
    yield put({ type: "RESET_PASSWORD_ERROR", payload: e });
    yield put({ type: "DELE_RESET_PASSWORD_DATA", payload: e });
  }
  // yield put({ type: "END_REQUEST" })
}

function* altaMasiva({ payload }) {
  try {
    let apiEndpoint = "/v1/auth/massive";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.post(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      console.log(res);
      yield put({ type: "ALTA_USER_MASIVO", payload: res });
    }
  } catch (e) {
    yield put({ type: "ERROR_USER_MASIVO", payload: e });
  }
  // yield put({ type: "END_REQUEST" })
}

function* getUsers() {
  try {
    let apiEndpoint = "/v1/users";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_USERS_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* getLanguages() {
  try {
    let apiEndpoint = "/v5/languages";
    const res = yield call(xcService.get, apiEndpoint);

    if (res) {
      yield put({ type: "SET_LANGUAGES_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getUsersCentro({ payload }) {
  try {
    let apiEndpoint = "/v1/usersCentro/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_USERS_CENTRO_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* getProfiles({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v1/profiles";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res) {
      yield all([
        put({ type: "SET_PROFILES_LIST", payload: res }),
        call(resolve)
      ]);
    }
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* deleteUser({ payload }) {
  const {resolve, reject, id}  = payload;

  try {
    let apiEndpoint = "/v1/user/" + id;
    const res = yield call(xcService.deleteDetail, apiEndpoint);

    if (res) {
      yield put({ type: "API_DONE", payload: "" });

      if(resolve) yield call(resolve);
    } 

  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });

    if(reject) yield call(reject);
  }
}

function* updateUser({ payload }) {
  yield put({ type: "START_REQUEST" });
  try {
    let profileList = yield select(getProfileList);
    profileList.map(item => {
      if (item.nombre === payload.perfil) {
        payload.perfil = item.id;
      }
    });
    let apiEndpoint = "/v1/users";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.put(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: "" });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }

  yield put({ type: "END_REQUEST" });
}

function* updateProfil({ payload }) {
  const { resolve, reject, lang, ...profile } = payload;
  let apiEndpoint = "/v1/profiles";

  try {
    const res = yield call(xcService.put, apiEndpoint, profile, lang);

    if (res) {
      yield all([
        put({ type: "SET_PROFILES_LIST", payload: res }),
        call(resolve)
      ]);
    }
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* addProfile({ payload }) {
  yield put({ type: "START_REQUEST" });
  try {
    let profileList = yield select(getProfileList);
    payload.id = profileList[profileList.length - 1].id + 1;

    let apiEndpoint = "/v1/profiles";

    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.postToken(apiEndpoint, payload).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: "" });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* createUser() {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v4/cargos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_CARGOS", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* getCargos({ payload: { resolve, reject } }) {
  try {
    let apiEndpoint = "/v4/cargos";
    const res = yield call(xcService.get, apiEndpoint);

    if (res) {
      yield all([put({ type: "SET_CARGOS", payload: res }), call(resolve)]);
    }
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}
