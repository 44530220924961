import {
  FETCH_PERIODS,
  FETCH_SENT_EMAILS,
  SAVE_PERIODS,
  SAVE_SENT_EMAILS
} from "constants/settings.constant";

export function fetchPeriods(payload = {}) {
  return {
    type: FETCH_PERIODS,
    payload
  };
}

export function savePeriods(payload = {}) {
  return {
    type: SAVE_PERIODS,
    payload
  };
}

export function fetchSentEmails(payload = {}) {
  return {
    type: FETCH_SENT_EMAILS,
    payload
  };
}

export function saveSentEmails(payload = {}) {
  return {
    type: SAVE_SENT_EMAILS,
    payload
  };
}
