import React from "react";
import { makeStyles, Dialog, DialogContent } from "@material-ui/core";
import { DialogTitle } from "../../../components";

const useStyles = makeStyles({
  iframe: {
    width: 800,
    height: "calc(95vh - 100px)",
    border: 0
  },
  dialogContent: {
    display: "flex",
    padding: 0
  }
});

export function ShowHtml(props) {
  const { html, setHtml } = props;

  const classes = useStyles();

  const handleClose = () => {
    setHtml();
  };

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={!!html}>
      <DialogTitle onClose={handleClose}>Contenido HTML</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <iframe className={classes.iframe} srcDoc={html}></iframe>
      </DialogContent>
    </Dialog>
  );
}
