const initialState = { provincias: [], comunidades: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROVINCIAS_LIST":
      return {
        ...state,
        provincias: action.payload.data
      };
    case "SET_COMUNIDADES_LIST":
      return {
        ...state,
        comunidades: action.payload.data
      };

    default:
      return state;
  }
};
