import React from "react";
import {
  Button,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useStatus } from "helpers/custom-hooks.helper";

const initialState = {
  subject: "",
  receiver: "",
  status: "",
  startSent: null,
  endSent: null
};

export function EmailTrackerFilter(props) {
  const { onFilter, defaultFilter = {}, ...others } = props;
  const [state, setState] = useStatus({
    ...initialState,
    ...defaultFilter
  });

  const handleChangeDate = (value, name) => {
    setState({ [name]: value });
  };

  const handleChange = event => {
    setState({
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    onFilter(state);
  };

  const handleReset = () => {
    setState(initialState);

    onFilter(initialState);
  };

  return (
    <Card {...others}>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              value={state.subject}
              name="subject"
              onChange={handleChange}
              variant="outlined"
              label="Asunto"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              value={state.receiver}
              name="receiver"
              onChange={handleChange}
              variant="outlined"
              label="Receptor"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} style={{ minWidth: 190 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/yyyy"
              label="Enviado(inicio)"
              value={state.startSent}
              onChange={value => handleChangeDate(value, "startSent")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} style={{ minWidth: 190 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/yyyy"
              label="Enviado(fin)"
              value={state.endSent}
              onChange={value => handleChangeDate(value, "endSent")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Estado</InputLabel>
              <Select
                value={state.status}
                onChange={handleChange}
                name="status"
                label="Estado"
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value={true}>Enviados</MenuItem>
                <MenuItem value={false}>No enviados</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button color="primary" size="small" onClick={handleReset}>
              Deshacer
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
