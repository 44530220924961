import { takeEvery, call, put, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { toolkitsActions } from "actions/toolkitsActions";

export default function* watcherToolkits() {
  yield takeEvery(actionTypes.GET_TOOLKITS, getToolkits);
  yield takeEvery(actionTypes.CREATE_TOOLKITS, createToolkits);
  yield takeEvery(actionTypes.DELETE_TOOLKITS, deleteToolkits);
  yield takeEvery(actionTypes.UPDATE_TOOLKITS, updateToolkits);
}

function* getToolkits({ payload: { resolve, reject, lang } }) {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v3/toolkits";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res)
      yield all([
        put({ type: "SET_TOOLKITS_LIST", payload: res }),
        call(resolve, undefined)
      ]);
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* createToolkits({ payload }) {
  const { resolve, reject, lang, ...feetback } = payload;
  try {
    let apiEndpoint = "/v3/toolkits";

    const res = yield call(xcService.postToken, apiEndpoint, feetback, lang);

    if (res) yield put(toolkitsActions.getToolkits({ resolve, reject, lang }));
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* updateToolkits({ payload }) {
  const { resolve, reject, lang, ...feetback } = payload;
  try {
    let apiEndpoint = "/v3/toolkits";
    const res = yield call(xcService.patch, apiEndpoint, feetback, lang);

    if (res) yield put(toolkitsActions.getToolkits({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}

function* deleteToolkits({ payload }) {
  yield put({ type: "START_REQUEST" });
  try {
    let apiEndpoint = "/v3/deleteToolkits/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.deleteDetail(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "API_DONE", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }

  yield put({ type: "END_REQUEST" });
}
