import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  Typography
} from "@material-ui/core";
import { Clear, DoneAll } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { fetchCenterKeyAnswers } from "../../../actions/centrosActions";
import { DialogTitle, LoadingOverlay } from "../../../components";
import {
  MatrixAnswer,
  MultiChoiseAnswer,
  RatingAnswer,
  SingleChoiseAnswer
} from "./index";

const useStyles = makeStyles({
  expandIcon: {
    "&.Mui-expanded": {
      transform: "rotate(0deg)"
    }
  }
});

export function ShowKeyAnswers(props) {
  const { targetKey, setTargetKey } = props;
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (targetKey) initialRequest();
  }, [targetKey]);

  const handleClose = () => {
    setTargetKey(null);
  };

  const initialRequest = async () => {
    try {
      setLoading(true);

      const resp = await new Promise((resolve, reject) =>
        dispatch(
          fetchCenterKeyAnswers({
            reject,
            resolve,
            filter: targetKey
          })
        )
      );

      setQuestions(resp);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Dialog maxWidth="md" onClose={handleClose} open={!!targetKey}>
      <DialogTitle onClose={handleClose}>
        {targetKey?.centerName} - Clave {targetKey?.num}
      </DialogTitle>
      <DialogContent
        style={{ minHeight: 300, minWidth: 300, position: "relative" }}
        className="pb-4"
      >
        <LoadingOverlay isLoading={loading} />
        {questions.map(({ num, codigo, titulo, status, ...props }, i) => (
          <Accordion key={i}>
            <AccordionSummary
              classes={{ expandIcon: classes.expandIcon }}
              expandIcon={
                <>
                  {status === "INCOMPLETE" && <Clear color="error" />}
                  {status === "COMPLETE" && <DoneAll color="primary" />}
                  {status === "NOT_APPLICABLE" && (
                    <Typography variant="caption" color="textPrimary">
                      NO PROCEDE
                    </Typography>
                  )}
                </>
              }
            >
              <Typography variant="body2">
                {targetKey?.num}.{num}. {titulo}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {codigo === "RATING" && <RatingAnswer {...props} />}
              {codigo === "MULTI_CHOISE" && <MultiChoiseAnswer {...props} />}
              {codigo === "SINGLE_CHOISE" && <SingleChoiseAnswer {...props} />}
              {codigo === "MATRIX_MULTI_CHOISE" && <MatrixAnswer {...props} />}
              {codigo === "MATRIX_SINGLE_CHOISE" && (
                <MatrixAnswer {...props} checkbox={false} />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  );
}
