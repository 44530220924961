import { takeEvery, call, put, select, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { provinciasActions } from "actions/provinciasActions";

export default function* watcherProvincias() {
  yield takeEvery(actionTypes.GET_PROVINCIAS, getProvincias);
  yield takeEvery(actionTypes.ADD_PROVINCIA, addProvincia);
  yield takeEvery(actionTypes.UPDATE_PROVINCIA, updateProvincia);
}

export const getProvinciasList = state => state.provinciasReducer.provincias;

function* getProvincias({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v5/provincia";
    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res) yield put({ type: "SET_PROVINCIAS_LIST", payload: res });

    if (resolve) yield call(resolve, undefined);
  } catch (e) {
    if (resolve) yield call(reject, "Interal Error");
  }
  try {
    let apiEndpoint = "/v5/comunidades";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_COMUNIDADES_LIST", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* addProvincia({ payload }) {
  const { resolve, reject, lang, ...province } = payload;

  try {
    let apiEndpoint = "/v5/provincia";

    const res = yield call(xcService.postToken, apiEndpoint, province, lang);

    if (res)
      yield put(provinciasActions.getProvincias({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateProvincia({ payload }) {
  const { resolve, reject, lang, ...privince } = payload;

  try {
    let apiEndpoint = "/v5/provincia";

    const res = yield call(xcService.put, apiEndpoint, privince, lang);

    if (res)
      yield put(provinciasActions.getProvincias({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}
