import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "actions/userActions";

export const MainComponent = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getLanguages());
  }, []);

  return children;
};
