import { takeEvery, call, put, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import actionResources from "constants/resources_constant";
import { recursosActions } from "actions/recursosActions";
import { xcService } from "../services";
import { fileToBase64 } from "helpers/global_helper";

export default function* watcherRecursos() {
  yield takeEvery(actionTypes.GET_PERSPECTIVAS, getPerspectivas);
  yield takeEvery(actionTypes.SET_TIPOMATERIAL, getTipoMaterial);
  yield takeEvery(actionTypes.GET_RECURSOS, getRecursos);
  yield takeEvery(actionTypes.GET_COMUNIDADES_RECURSO, getComunidadesRecurso);
  yield takeEvery(actionTypes.GET_CURSOS_RECURSO, getCursosRecurso);
  yield takeEvery(actionTypes.UPDATE_RECURSO, updateRecurso);
  yield takeEvery(actionTypes.CREATE_RECURSO, createRecurso);
  yield takeEvery(actionTypes.DELETE_RECURSO, deleteRecurso);
  yield takeEvery(actionResources.SET_FILE_RESOURCES, setResources);
}

function* getTipoMaterial() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v11/tipomaterial";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.SET_TIPOMATERIAL_DATA, payload: res.data });
    } else {
      console.log("ERROR");
    }
  } catch (e) {}
  yield put({ type: actionTypes.END_REQUEST });
}

function* getPerspectivas() {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v11/perspectivas";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({ type: actionTypes.GET_PERSPECTIVAS_DATA, payload: res.data });
    } else {
      console.log("ERROR");
    }
  } catch (e) {}
  yield put({ type: actionTypes.END_REQUEST });
}

function* getRecursos({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v11/recursos";
    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res)
      yield all([
        put({ type: "SET_RECURSOS_LIST", payload: res }),
        call(resolve, undefined)
      ]);
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* getComunidadesRecurso({ payload }) {
  yield put({ type: "START_REQUEST" });
  try {
    let apiEndpoint = "/v11/comunidadesrecurso/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_COMUNIDADES_RECURSO", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }

  yield put({ type: "END_REQUEST" });
}

function* getCursosRecurso({ payload }) {
  yield put({ type: "START_REQUEST" });
  try {
    let apiEndpoint = "/v11/cursosrecurso/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "SET_CURSOS_RECURSO", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
  yield put({ type: "END_REQUEST" });
}

function* updateRecurso({ payload }) {
  const { resolve, reject, lang, ...resource } = payload;

  try {
    let apiEndpoint = "/v11/recursos";

    if(resource.centerLogo instanceof File) {
      resource.centerLogo = yield call(fileToBase64, resource.centerLogo);
    } else {
      resource.centerLogo = null;
    }

    if(resource.document instanceof File) {
      resource.document = yield call(fileToBase64, resource.document);
    } else {
      resource.documentPath = resource.document;
      resource.document = null;
    }

    const res = yield call(xcService.patch, apiEndpoint, resource, lang);

    if (res) yield put(recursosActions.getRecursos({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}

function* createRecurso({ payload }) {
  const { resolve, reject, lang, ...resource } = payload;

  try {
    let apiEndpoint = "/v11/recursos";

    if(resource.centerLogo instanceof File) {
      resource.centerLogo = yield call(fileToBase64, resource.centerLogo);
    }

    if(resource.document instanceof File) {
      resource.document = yield call(fileToBase64, resource.document);
    }

    const res = yield call(xcService.postToken, apiEndpoint, resource, lang);

    if (res) yield put(recursosActions.getRecursos({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* deleteRecurso({ payload }) {
  const { resolve, reject, id, lang } = payload;

  try {
    let apiEndpoint = "/v11/recurso/" + id;

    const res = yield call(xcService.deleteDetail, apiEndpoint);

    if (res) yield put(recursosActions.getRecursos({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}

function* setResources({ payload }) {
  const { resolve, reject, lang, ...resource } = payload;
  try {
    let apiEndpoint = "/v11/resources/upload";
    const res = yield call(xcService.postToken, apiEndpoint, resource, lang);

    yield call(resolve, res);
  } catch (e) {
    yield call(reject, "Internal Error");
  }
}
