import EmailTracker from "./views/EmailTracker/EmailTracker";
import CenterQuizInfo from "./views/CenterQuizInfo/CenterQuizInfo";
import Login from "views/Login/login.js";
import UserManagement from "views/userManagement/UserManagement.js";
import CentrosManagement from "views/centrosManagement/CentrosManagement.js";
import RecursosManagementContainer from "views/resoursesManagement/contenidosManagement";
import {
  Dashboard,
  Person,
  LibraryBooks,
  CloudDownload,
  EmailOutlined,
  Assessment
} from "@material-ui/icons";

import { DownloadResults } from "./views/Reports/DownloadResults";
import { TableChart } from "@material-ui/icons";
import DashboardPage from "views/CentersDashboard/Dashboard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: TableChart,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/datos",
    name: "Seguimiento centros",
    icon: Assessment,
    component: CenterQuizInfo,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: Login,
    layout: "/auth"
  },
  {
    path: "/usermngmt",
    name: "Gestión de usuarios",
    icon: Person,
    component: UserManagement,
    layout: "/admin"
  },
  {
    path: "/recursos",
    name: "Recursos y Buenas prácticas",
    icon: "content_paste",
    component: RecursosManagementContainer,
    layout: "/admin"
  },
  {
    path: "/email-tracker",
    name: "Correos enviados",
    icon: EmailOutlined,
    component: EmailTracker,
    layout: "/admin"
  },
  {
    path: "/centros",
    name: "BBDD Centros",
    icon: LibraryBooks,
    component: CentrosManagement,
    layout: "/admin"
  },
  {
    path: "/download-results",
    name: "Descargar Resultados",
    icon: CloudDownload,
    component: DownloadResults,
    layout: "/admin"
  }
];

export default dashboardRoutes;
