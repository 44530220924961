import actionTypes from "constants/actionTypes";

export const toolkitsActions = {
  getToolkits,
  createToolkits,
  deleteToolkits,
  updateToolkits
};

function getToolkits(payload = {}) {
  return {
    type: actionTypes.GET_TOOLKITS,
    payload
  };
}

function createToolkits(payload) {
  return {
    type: actionTypes.CREATE_TOOLKITS,
    payload: payload
  };
}

function updateToolkits(payload) {
  return {
    type: actionTypes.UPDATE_TOOLKITS,
    payload: payload
  };
}

function deleteToolkits(payload) {
  return {
    type: actionTypes.DELETE_TOOLKITS,
    payload: payload
  };
}
