const initialState = { toolkits: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOOLKITS_LIST":
      return {
        ...state,
        toolkits: action.payload.data
      };

    case "SAVE_TOOLKIT":
      return {
        ...state,
        saveToolkit: action.payload.data
      };

    default:
      return state;
  }
};
