import React from "react";
import { CentersDashboard } from "./CentersDashboard";
import { Card, CardContent } from "@material-ui/core";
import EvaluationByPeriod from "views/CentersEvaluation/EvaluationsByPeriod";
import CentersResultsByKey from "views/CentersResultsByKey/CentersResultsByKey";

export default function NewDashboard() {
  return (
    <div>
      <Card className="mb-4">
        <CardContent>
          <CentersDashboard />
        </CardContent>
      </Card>
      <Card className="mb-4">
        <CardContent>
          <EvaluationByPeriod />
        </CardContent>
      </Card>
      <Card className="mb-4">
        <CardContent>
          <CentersResultsByKey />
        </CardContent>
      </Card>
    </div>
  );
}
