import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import routes from "routes.js";

const user = JSON.parse(localStorage.getItem("user"));
const token = user ? user.token : null;
const perfil = user ? user.perfil : null;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if (token && perfil === 0) {
          return (
            <Switch key={key}>
              <Redirect from="/auth" to="/admin/dashboard" />
            </Switch>
          );
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/auth/login" />
  </Switch>
);

export default function Auth() {
  return <div>{switchRoutes}</div>;
}
