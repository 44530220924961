import actionTypes from "constants/actionTypes";

const initialState = {
  cursos: [],
  cursosCentros: null,
  showInactives: false,
  selectedCentro: -1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURSOS_LIST":
      return {
        ...state,
        cursos: action.payload.data
      };
    case "SET_CURSOS_CENTROS":
      return {
        ...state,
        cursosCentros: action.payload.data
      };
    case "SET_SHOW_INACTIVES":
      return {
        ...state,
        showInactives: action.payload
      };
    case "SET_CENTRO_SELECTED":
      return {
        ...state,
        selectedCentro: action.payload
      };
    default:
      return state;
  }
};
