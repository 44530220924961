import { takeEvery, call, put, all } from "redux-saga/effects";
import actionTypes from "constants/actionTypes";
import { xcService } from "../services";
import { contenidosActions } from "actions/contenidosActions";

export default function* watcherContenidos() {
  yield takeEvery(actionTypes.GET_CONTENIDOS, getContenidos);
  yield takeEvery(actionTypes.UPDATE_CONTENIDO, updateContenido);
  yield takeEvery(actionTypes.DELETE_CONTENIDO_SAGA, deleteContenido);
  yield takeEvery(actionTypes.CREATE_CONTENIDO, createContenido);

  yield takeEvery(actionTypes.GET_DOCUMENTOS, documentos);
  yield takeEvery(actionTypes.DELETE_DOCUMENTOS, delDocumento);
}

function* delDocumento({ payload }) {
  yield put({ type: "START_REQUEST" });

  console.log(payload);

  try {
    let apiEndpoint = "/v1/documentos/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.deleteDetail(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "GET_DOCUMENTOS", payload: "" });
    }
  } catch (e) {
    console.log(111, e);
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* documentos() {
  yield put({ type: "START_REQUEST" });

  try {
    let apiEndpoint = "/v1/documentos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          xcService.get(apiEndpoint).then(response => {
            resolve(response);
          });
        })
    );
    if (res) {
      yield put({ type: "GET_DOCUMENTOS_DATA", payload: res });
    }
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* getContenidos({ payload: { resolve, reject, lang } }) {
  try {
    let apiEndpoint = "/v10/contenidos";

    const res = yield call(xcService.get, apiEndpoint, lang);

    if (res)
      yield all([
        put({ type: "SET_CONTENIDOS_LIST", payload: res }),
        call(resolve, undefined)
      ]);
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* updateContenido({ payload }) {
  const { resolve, reject, lang, ...content } = payload;
  try {
    let apiEndpoint = "/v10/contenidos";
    const res = yield call(xcService.patch, apiEndpoint, content, lang);

    if (res)
      yield put(contenidosActions.getContenidos({ resolve, reject, lang }));
  } catch (e) {
    yield call(reject, "Interal Error");
  }
}

function* deleteContenido({ payload }) {
  const { resolve, reject, id, lang } = payload;

  try {
    let apiEndpoint = "/v10/contenido/" + id;

    const res = yield call(xcService.deleteDetail, apiEndpoint);

    if (res)
      yield put(contenidosActions.getContenidos({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}

function* createContenido({ payload }) {
  const { resolve, reject, lang, ...content } = payload;

  try {
    let apiEndpoint = "/v10/contenidos";
    const res = yield call(xcService.postToken, apiEndpoint, content, lang);

    if (res)
      yield put(contenidosActions.getContenidos({ resolve, reject, lang }));
  } catch (e) {
    console.log(e);
    yield call(reject, "Interal Error");
  }
}
