import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { cursosActions } from "actions/cursosActions";
import ShowCursos_centro from "./ShowCursos_Centro.js";
import ModifyCursos from "./ModifyCursos.js";
import CursosCentrosManagement from "./cursosCentrosManagement.js";

export default function CursosManagement(props) {
  const dispatch = useDispatch();
  const centerSelected = props.centroSeleccionado;
  const cursosCentros = useSelector(state => state.cursosReducer.cursosCentros);

  const cursos = useSelector(state => state.cursosReducer.cursos);

  useEffect(() => {
    if (centerSelected) {
      dispatch(cursosActions.getCursos());
      dispatch(cursosActions.getCursosCentros({ id: centerSelected }));
    }
  }, [centerSelected]); //cambiar por props

  return (
    <div>
      {cursos && cursosCentros && props.infoAmpliadaCentro && (
        <ShowCursos_centro cursos={cursos} cursosCentros={cursosCentros} />
      )}

      {!props.infoAmpliadaCentro && (
        <GridContainer>
          <GridItem xs={6}>{/* <CursosCentrosManagement /> */}</GridItem>

          <GridItem xs={6}>
            <ModifyCursos />
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
